import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
    },
    wrapper: {
        border: 'solid 1px #e74c3c',
        maxWidth: 700,
        padding: '64px 128px',
        margin: 24,
    },
    title: {
        fontSize: 24,
        marginBottom: 32,
    },
})

export default () => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>Non autorisé</h1>
            </div>
        </div>
    )
}
