import React from 'react'
import styles from './CodeOfConductSteps.module.css'

export default () => (
    <div>
        <div className={styles.intro}>
            <h2 className={styles.title}>Code de savoir-vivre</h2>
            <p>
                destiné aux étudiant·es
                <br />
                de la Haute École d’Ingénierie et de Gestion
                <br />
                du Canton de Vaud
            </p>
        </div>
        <p>
            La HEIG-VD est une Haute École spécialisée qui dispense un enseignement de niveau tertiaire universitaire
            axé sur la pratique et assurant aux étudiant·es une formation à haute valeur ajoutée, adaptée aux exigences
            actuelles. Institution offrant des opportunités de synergies entre enseignant·es, étudiant·es et entreprises
            pour l’émergence de nouvelles compétences, individuelles ou collectives, elle est reconnue comme une{' '}
            <em>université des sciences appliquées</em>. Dans cet environnement spécifique, le présent code de
            savoir-vivre permet de rappeler quelques règles de vie en communauté au sein de la HEIG-VD.
        </p>
    </div>
)
