import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { format, parseJSON } from 'date-fns'
import React from 'react'
import request from 'request-promise'
import Auth from '../Auth'
import Form from '../Form'
import PageStatus from '../PageStatus'
import baseStyles from './Base.module.css'

const { REACT_APP_API_URI } = process.env

const useStyles = makeStyles({
    input: {
        fontSize: 'inherit',
        fontFamily: 'inherit',
        padding: '1rem',
        display: 'block',
        minWidth: '100%',
        maxWidth: '100%',
    },
    checkbox: {
        '& span': {
            fontSize: 'inherit',
        },
    },
    link: {
        margin: '2rem 0',
        display: 'block',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
})

const updateForm =
    ({ token }) =>
    async (form) =>
        request({
            uri: `${REACT_APP_API_URI}/forms`,
            method: 'POST',
            body: form,
            json: true,
            headers: { authorization: `Bearer ${token}` },
        })

export default ({ path }) => {
    const styles = useStyles()
    const { form, setAttribute } = Form.useContainer()
    const { active = false } = form[path] || {}
    const { token } = Auth.useContainer()

    const onActiveChange = async () => {
        const updatedForm = setAttribute(path)('active', !active)
        updateForm({ token })(updatedForm)
    }

    return (
        <div className={baseStyles.root}>
            <h2>Demande d'équivalence</h2>
            <PageStatus path={path} />

            <p>
                En vertu de l’article 3 du{' '}
                <a
                    href="https://heig-vd.ch/docs/default-source/doc-a-propos/r%C3%A9glementation/rglt-etudes-heig-vd.pdf?sfvrsn=7f149dea_2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    règlement d’études
                </a>{' '}
                de la Haute école d’ingénierie et de gestion du Canton de Vaud ;
            </p>
            <p>
                <strong>L’étudiant qui peut démontrer</strong> l’acquisition, préalablement à ses études, de
                connaissances, voire de compétences correspondantes ou équivalents à celles enseignées dans un module
                peut être mis au bénéfice d’une équivalence de cours.
                <br />
                L’équivalence se traduit par la validation de module (sans note). Les crédits correspondants sont pris
                en compte pour l’octroi du titre.
            </p>
            <p>
                Les demandes d’équivalence doivent être déposées <strong>au moment de la demande d’admission</strong>{' '}
                pour un ou des modules. A moins d'un changement de plan d'étude aucune demande ultérieure ne sera
                acceptée, sauf pour les modules de langues. Par exemple, vous ne pourrez pas demander en fin de 1
                <sup>ère</sup>
                année une équivalence pour des modules de 2<sup>ème</sup>.
            </p>
            <p>
                La demande, qui doit parvenir{' '}
                <strong>
                    avant la fin de la 1<sup>ère</sup> semaine de cours
                </strong>{' '}
                du 1<sup>er</sup> semestre, sera traitée par votre responsable de filière, au plus tard au terme de la 3
                <sup>ème</sup> semaine de cours.
            </p>
            <p>Soyez clair et précis dans la rédaction de votre demande :</p>
            <ol>
                <li>
                    Indiquez <strong>la formation</strong> préalablement suivie pour laquelle vous estimez qu’une
                    demande est possible.
                </li>
                <li>
                    En vous aidant du{' '}
                    <a href="https://gaps.heig-vd.ch/public/plans.php" target="_blank" rel="noopener noreferrer">
                        plan d’étude
                    </a>
                    , indiquez <strong>sous forme de liste</strong>, le(s) module(s) ou le(s) unité(s) pour lesquelles
                    vous faites une demande.
                </li>
            </ol>

            <FormControlLabel
                control={<Checkbox checked={active} onChange={onActiveChange} />}
                label="Je souhaite faire une demande d'équivalence"
                className={styles.checkbox}
            />
            {active && (
                <>
                    <p>
                        Accès à la plateforme{' '}
                        <a href="https://equivalences.heig-vd.ch" target="_blank" rel="noopener noreferrer">
                            Demandes d'équivalence
                        </a>
                    </p>
                    <Equivalences token={token} />
                </>
            )}
        </div>
    )
}

const Equivalences = ({ token }) => {
    const styles = makeStyles({
        root: { marginTop: '32px' },
    })()

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        const fetch = async () => {
            const uri = 'https://equivalences-api.blacktree.io/equivalences-aloha'
            const res = await request({
                uri,
                method: 'GET',
                json: true,
                headers: { authorization: `Bearer ${token}` },
            })
            setData(res)
        }
        fetch()
    }, [token])

    return data.length > 0 ? (
        <div className={styles.root}>
            <h3>Statut de vos demandes</h3>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Modules</TableCell>
                            <TableCell>Statut</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.createdAt}>
                                <TableCell component="th" scope="row">
                                    {row.modules.join(', ')}
                                </TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{format(parseJSON(row.createdAt), 'dd.MM.yyyy')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : null
}
