import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import Student from '../Student'
import { isComem, isHegComem } from '../lib/utils'
import { Link } from '@reach/router'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Evaluation de votre niveau de mathématique</h2>
            <PageStatus path={path} />
            <p>
                La HEIG-VD a développé un outil d’auto-évaluation en mathématiques servant d’indication quant à la
                nécessité ou non d’améliorer votre niveau avant la rentrée :
            </p>
            <p>
                <a
                    href="https://cyberlearn.hes-so.ch/course/view.php?id=15955"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Auto-évaluation en math
                </a>{' '}
            </p>
            <p>
                <strong>
                    Se connecter avec l’établissement HES-SO – Haute école spécialisée de Suisse occidentale. Utilisez
                    ensuite votre identifiant et mot de passe edu-ID.
                </strong>{' '}
            </p>
            <p>
                <img
                    src="https://heig-vd.ch/images/default-source/img-centre-formation-de-base/inscription/auth-cyberlearn.png"
                    alt="auth-cyberlearn"
                />
            </p>
            <p>
                Commencez par le « test d’évaluation préalable » (obligatoire), puis vous pouvez utiliser autant de fois
                que vous le souhaitez les autres tests.
            </p>
            {isHegComem(student) ? (
                <>
                    <p>
                        En complément, la HEIG-VD organise avant la rentrée, des{' '}
                        {isComem(student) ? (
                            <Link to="/2024/inscription-unites-preparatoires">cours préparatoires</Link>
                        ) : (
                            <Link to="/2024/cours-preparatoires">cours préparatoires</Link>
                        )}{' '}
                        auxquelles vous pouvez vous auxquels vous pouvez vous inscrire dans la section dédiée.
                    </p>
                    <h3>Autres ressources</h3>
                    <p>
                        Pour celles et ceux qui souhaitent effectuer une mise à niveau en autodidacte, le{' '}
                        <strong>MOOC HES-SO</strong> «{' '}
                        <a
                            href="https://numerique.hes-so.ch/?redirect=0&cid=161"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vous reprendrez bien un peu de maths ?
                        </a>{' '}
                        » de Titienne Bitschnau est à disposition. Ce cours en ligne aborde en vidéo les bases du calcul
                        littéral et de la résolution d’équations (puissances, racines, polynômes, équation du 1er et du
                        2ème degré, exponentielles et logarithmes, etc.). Des vidéos, des exercices corrigés, des quiz
                        d’évaluation et de validation font de ce cours un excellent moyen de préparer votre rentrée.
                        L’inscription est gratuite et possible en tout temps.
                    </p>
                </>
            ) : (
                <p>
                    En complément, la HEIG-VD organise avant la rentrée, des{' '}
                    <Link to="/2024/inscription-unites-preparatoires">unités préparatoires</Link> auxquelles vous pouvez
                    vous inscrire dans la section dédiée.
                </p>
            )}
        </div>
    )
}
