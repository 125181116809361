import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Logo from './LogoHeig'
import { Link } from '@reach/router'

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        color: '#777',
        textDecoration: 'none',
        textAlign: 'center',
    },
    logo: {
        position: 'absolute',
        width: 40,
        left: 24,
        top: 10,
        // eslint-disable-next-line
        ['@media (min-width:600px)']: {
            width: 48,
            top: 16,
        },
    },
    appBar: {
        background: 'white',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

export default () => {
    const styles = useStyles()
    return (
        <AppBar position="static" className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <Logo className={styles.logo} />
                <Link to="/" className={styles.title}>
                    <Typography variant="h6" className={styles.title}>
                        Admissions HEIG-VD
                    </Typography>
                </Link>
            </Toolbar>
        </AppBar>
    )
}
