import React from 'react'
import OxfordAccesCodes from './OxfordAccessCodes'
import OxfordInstructions from './OxfordInstructions'

export default (props) => (
    <>
        <h3>Anglais</h3>
        <ul>
            <li>
                <h4> Oxford Online Placement Test</h4>
                <p>Dernier délai : 30 juin</p>
                <OxfordInstructions {...props} />
                <OxfordAccesCodes {...props} />
            </li>
            <li>
                <h4>Profil linguistique</h4>
                <p>
                    Remplir le{' '}
                    <a href="https://lingo-log.heig-vd.ch/en" target="_blank" rel="noopener noreferrer">
                        formulaire de profil linguistique
                    </a>{' '}
                    (dernier délai : 30 juin)
                </p>
            </li>
        </ul>
    </>
)
