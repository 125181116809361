import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Problèmes de santé spécifiques</h2>
            <PageStatus path={path} />
            <p>
                La HEIG-VD a mis sur pied un système vous permettant d’annoncer un problème de santé spécifique et de
                nous indiquer la conduite à tenir en cas de survenue du problème (par exemple étudiant·e épileptique,
                étudiant·e diabétique, etc).
            </p>
            <p>
                Si vous avez un problème de santé spécifique, nous vous proposons de remplir ce{' '}
                <a href="https://heig.ch/aloha-formulaire-problemes-sante-specifique" target="_blank" rel="noopener noreferrer">
                    formulaire
                </a>{' '}
                et de le renvoyer au Centre Formation de Base, par courrier électronique à l’adresse :{' '}
                <a href="mailto:secretariat.cfb@heig-vd.ch">secretariat.cfb@heig-vd.ch</a>
            </p>
            <p>
                Le Centre Formation de Base préviendra chaque semestre vos enseignant·es, responsable de filière et
                secrétariat afin qu’en cas de survenue d’un problème, ils prennent les bonnes mesures vous concernant.
                Bien entendu, les données que vous nous transmettrez resteront confidentielles pour le reste de l’École.
            </p>
        </div>
    )
}
