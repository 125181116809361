import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Rentrée et calendrier académique</h2>
            <PageStatus path={path} />
            <p>
                Rentrée académique le <strong>16 septembre 2024</strong>
            </p>
            <p>
                1<sup>er</sup> jour de cours le 17 septembre 2024 (16 septembre 2024 jour férié)
            </p>
            <p>
                Prenez attention au{' '}
                <a
                    href="https://intra.heig-vd.ch/academique/calendriers-academiques/Pages/calendriers-academiques.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    calendrier académique
                </a>{' '}
                de l'École qui vous permettra d’organiser vos activités et loisirs en fonction des semaines de cours et
                d’examens.
            </p>
        </div>
    )
}
