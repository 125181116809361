import React from 'react'
import {
    coursPreparatoiresTocFilter,
    horaireDesCoursTocFilter,
    inscriptionUnitesPreparatoiresTocFilter,
    isGenieTerritorial,
    isHeg,
    isPt,
    journeeAccueilTocFilter,
    verificationNiveauMathTocFilter,
    isGerman,
} from './lib/utils'
import Student from './Student'
import styles from './TOC.module.css'
import TOCEntry from './TOCEntry'

const seesAhesMenu = ({ ahesRequired, ahesFin, visaRequired, visaAcquired }) =>
    ahesRequired && !(!ahesFin && visaRequired && !visaAcquired)

const isHegAndPt = (student) => isHeg(student) && isPt(student)

const isEngineerGerman = (student) => !isHeg(student) && isGerman(student)
const cantSeeVerificationNiveauxLangues = (student) => isGenieTerritorial(student) || isEngineerGerman(student)

export default ({ disabled }) => {
    const { student } = Student.useContainer()
    return (
        <div className={styles.root}>
            <div className={styles.section}>
                <span className={styles.title}>A effectuer de suite</span>
                <ul className={styles.list}>
                    <TOCEntry to="/2024/comptes-acces">Comptes d'accès</TOCEntry>
                    {seesAhesMenu(student) && (
                        <TOCEntry to="/2024/financement-de-vos-etudes" disabled={disabled}>
                            Financement de vos études – Formulaire AHES à remplir
                        </TOCEntry>
                    )}
                    {!cantSeeVerificationNiveauxLangues(student) && (
                        <TOCEntry to="/2024/verification-niveaux-langues" disabled={disabled}>
                            Vérification des niveaux de langues
                        </TOCEntry>
                    )}
                    <TOCEntry to="/2024/code-de-savoir-vivre" disabled={disabled}>
                        Code de savoir-vivre
                    </TOCEntry>
                    <TOCEntry to="/2024/assurance-maladie-et-accident" disabled={disabled}>
                        Assurance maladie et accident
                    </TOCEntry>
                    {isHegAndPt(student) && (
                        <>
                            <TOCEntry to="/2024/cours-marketing" disabled={disabled}>
                                Cours de marketing en français ou en anglais
                            </TOCEntry>
                        </>
                    )}
                </ul>
            </div>
            <div className={styles.section}>
                <span className={styles.title}>Préparation</span>
                <ul className={styles.list}>
                    {verificationNiveauMathTocFilter(student) && (
                        <TOCEntry to="/2024/evaluation-niveau-math" disabled={disabled}>
                            Evaluation de votre niveau de mathématique
                        </TOCEntry>
                    )}
                    {coursPreparatoiresTocFilter(student) && (
                        <TOCEntry to="/2024/cours-preparatoires" disabled={disabled}>
                            Inscription aux cours préparatoires
                        </TOCEntry>
                    )}
                    {inscriptionUnitesPreparatoiresTocFilter(student) && (
                        <TOCEntry to="/2024/inscription-unites-preparatoires" disabled={disabled}>
                            Inscription aux unités préparatoires
                        </TOCEntry>
                    )}
                    <TOCEntry to="/2024/demande-equivalence" disabled={disabled}>
                        Demande d'équivalence
                    </TOCEntry>
                </ul>
            </div>
            <div className={styles.section}>
                <span className={styles.title}>Immatriculation – Rentrée académique</span>
                <ul className={styles.list}>
                    <TOCEntry to="/2024/preparation-a-l-immatriculation" disabled={disabled}>
                        Votre immatriculation
                    </TOCEntry>
                    {journeeAccueilTocFilter(student) && (
                        <TOCEntry to="/2024/journee-d-accueil" disabled={disabled}>
                            Journée d’accueil - Présence obligatoire
                        </TOCEntry>
                    )}
                    <TOCEntry to="/2024/rentree-et-calendrier-academique" disabled={disabled}>
                        Rentrée et calendrier académique
                    </TOCEntry>
                    <TOCEntry to="/2024/attestation-d-etudes" disabled={disabled}>
                        Attestation d’études
                    </TOCEntry>
                </ul>
            </div>
            <div className={styles.section}>
                <span className={styles.title}>Informations importantes</span>
                <ul className={styles.list}>
                    <TOCEntry to="/2024/ordinateur-portable" disabled={disabled}>
                        Ordinateur portable
                    </TOCEntry>
                    <TOCEntry to="/2024/taxe-annuelle-etudes" disabled={disabled}>
                        Taxe annuelle d'études
                    </TOCEntry>
                    <TOCEntry to="/2024/votre-carte-etudiant-e" disabled={disabled}>
                        Votre carte d'étudiant&#903;e
                    </TOCEntry>
                    <TOCEntry to="/2024/guide-de-l-etudiant-e" disabled={disabled}>
                        Guide de l’étudiant&#903;e
                    </TOCEntry>
                    {horaireDesCoursTocFilter(student) && (
                        <TOCEntry to="/2024/horaire-des-cours" disabled={disabled}>
                            Horaire des cours
                        </TOCEntry>
                    )}
                    <TOCEntry to="/2024/problemes-sante-specifiques" disabled={disabled}>
                        Problèmes de santé spécifiques
                    </TOCEntry>
                    <TOCEntry to="/2024/situation-de-handicap-ou-maladie" disabled={disabled}>
                        Situation de handicap ou maladie
                    </TOCEntry>
                    <TOCEntry to="/2024/studios-appartements-chambres" disabled={disabled}>
                        Studios / Appartements / Chambres
                    </TOCEntry>
                    <TOCEntry to="/2024/mobilite" disabled={disabled}>
                        Mobilité - Venir à la HEIG-VD
                    </TOCEntry>
                    <TOCEntry to="/2024/renonciation" disabled={disabled}>
                        Renonciation
                    </TOCEntry>
                </ul>
            </div>
        </div>
    )
}
