import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Mobilité - Venir à la HEIG-VD</h2>
            <PageStatus path={path} />
            <p>
                Nous vous invitons à prendre connaissance des informations de{' '}
                <a
                    href="https://heig-vd.ch/campus/mobilite/venir-a-la-heig-vd"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    mobilité à la HEIG-VD
                </a>
                .
            </p>
        </div>
    )
}
