import React from 'react'

export default () => (
    <div>
        <p>
            Chaque collaboratrice et collaborateur œuvre au quotidien pour assurer la bonne réputation de la Haute École
            et promouvoir son image auprès de ses partenaires, des médias et du public. Il est dès lors attendu de
            chaque étudiant·e de témoigner d’un{' '}
            <strong>comportement exemplaire lorsqu’il ou elle représente la Haute École</strong>. Ceci se traduit en
            particulier par :
        </p>
        <ul>
            <li>Une utilisation adéquate de l’image de l’École</li>
            <li>Un comportement correspondant aux valeurs véhiculées par la HEIG-VD</li>
            <li>Une courtoisie exemplaire envers les partenaires de l’établissement</li>
        </ul>
    </div>
)
