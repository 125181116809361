import React from 'react'
import Student from '../../Student'

export default () => {
    const { student } = Student.useContainer()
    return (
        <>
            <h3>Allemand</h3>
            <ul>
                <li>
                    <h4>Altissia</h4>
                    <p>
                        Vous avez reçu ou vous recevrez prochainement un lien d’<strong>Altissia</strong> sur votre
                        adresse e-mail privée afin d’effectuer votre test d’Allemand.
                    </p>
                    <p>
                        <strong>Vérifiez que l'e-mail n'a pas été dirigé vers votre dossier de spams.</strong>
                    </p>
                </li>
                <li>
                    <h4>Profil linguistique</h4>
                    <p>
                        Remplir le{' '}
                        <a href="https://lingo-log.heig-vd.ch/de" target="_blank" rel="noopener noreferrer">
                            formulaire de profil linguistique
                        </a>{' '}
                        (dernier délai : 30 juin)
                    </p>
                    <p></p>
                </li>
            </ul>
        </>
    )
}
