import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Studios / Appartements / Chambres</h2>
            <PageStatus path={path} />
            <p>
                Afin de faciliter la recherche d'un logement sur le lieu de vos études, nous maintenons à jour un
                fichier de quelques adresses. Cette liste peut être consultée sur notre site Internet :{' '}
                <a href="https://heig-vd.ch/campus/vivre-et-etudier-a-yverdon/#se-loger" target="_blank">
                    https://heig-vd.ch/campus/vivre-et-etudier-a-yverdon/#se-loger
                </a>{' '}
                (réservée uniquement aux étudiant·es de la HEIG-VD).
            </p>
            <p>
                Attention aux arnaques :{' '}
                <a href="https://heig.ch/arnaques-logements" target="_blank" rel="noopener noreferrer">
                    information de la police vaudoise
                </a>
            </p>
        </div>
    )
}
