import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import { applyTo, cond, always, T } from 'ramda'
import Student from '../Student'
import { isEte, isTic, isTin, isComem, isEcg, isElci, isMtecOrSyndOrIgis } from '../lib/utils'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Inscription aux unités préparatoires</h2>
            <PageStatus path={path} />
            {applyTo(
                student,
                cond([
                    [
                        isEcg,
                        always(
                            <>
                                <p>
                                    Des unités préparatoires (mises à niveau) permettant aux nouveaux étudiants de
                                    combler des lacunes qu'ils auraient dans certaines disciplines seront organisées à
                                    la HEIG-VD, <strong>du lundi 19 août au vendredi 6 septembre 2024</strong>. Pour les
                                    étudiants inscrits dans le département EC+G il s’agit de l’unité préparatoire :
                                </p>
                                <ul>
                                    <li>
                                        <strong>
                                            UPMath (mathématiques) → conseillé aux étudiant·es peu sûrs en maths.
                                        </strong>
                                    </li>
                                </ul>
                                <h3>Informations</h3>
                                <ul>
                                    <li>
                                        Quand: du <strong>lundi 19 août au vendredi 6 septembre 2024</strong>
                                    </li>
                                    <li>Nombre de périodes de cours : 36.</li>
                                    <li>Horaires par demi-journées : matin 8h30-12h00 et après-midi 13h15-16h30.</li>
                                    <li>Nombre de places limité, par ordre d’inscription.</li>
                                    <li>Pas de participation sans inscription acceptée.</li>
                                    <li>
                                        Présence obligatoire pour celles et ceux dont les inscriptions auront été
                                        acceptées.
                                    </li>
                                    <li>Cette unité ne donne pas droit à des crédits ECTS.</li>
                                    <li>
                                        Cette unité est soumise à un contrôle continu (pas d’examen) dont la moyenne
                                        figurera dans le bulletin de notes, au même titre que les éventuelles absences.
                                    </li>
                                    <li>
                                        Sont concerné-e-s : les étudiant·es inscrit·e·s pour commencer leurs études cet
                                        automne.
                                    </li>
                                    <li>
                                        Vous pouvez consulter le descriptif de la fiche d’unité des unités préparatoires
                                        de mathématiques sur le lien suivant :{' '}
                                        <a href="https://heig.ch/5yaw6" target="_blank" rel="noopener noreferrer">
                                            https://heig.ch/5yaw6
                                        </a>{' '}
                                    </li>
                                    <li>
                                        Inscription : par e-mail auprès de la secrétaire du département EC+G{' '}
                                        <a href="mailto:secretariat.ECG@heig-vd.ch">secretariat.ECG@heig-vd.ch</a>{' '}
                                    </li>
                                    <li>
                                        <strong>Délai d’inscription : vendredi 2 août 2024.</strong>
                                    </li>
                                </ul>
                            </>
                        ),
                    ],
                    [
                        isComem,
                        always(
                            <>
                                <p>
                                    Vous avez choisi d’entreprendre une formation diversifiée et passionnante qui vous
                                    amènera vers des métiers qui le seront tout autant. Ces études d’ingénieur sont
                                    également très exigeantes et vous demanderont un travail conséquent.
                                </p>
                                <p>
                                    Pendant la formation, nous vous proposerons de l’aide sous forme de « tutorats »
                                    dans certaines matières qui posent parfois problème aux étudiant·es. Mais il est
                                    primordial que vous puissiez partir tout de suite sur de bonnes bases, c’est
                                    pourquoi nous organisons trois cours préparatoires.
                                </p>
                                <ul>
                                    <li>Mathématiques </li>
                                    <li>Introduction à la communication visuelle</li>
                                    <li>comptabilité</li>
                                </ul>
                                <p>
                                    Vous serez d’office inscrit-e dans au moins deux de ces trois cours, en fonction de
                                    votre formation de base. Le troisième peut être suivi sur une base volontaire, le
                                    cas échéant.
                                    <br />
                                    Ces cours visent à vous donner les prérequis pour suivre sereinement les matières de
                                    première année. L’expérience montre que ces cours sont nécessaires.
                                </p>
                                <h3>Horaires des cours </h3>
                                <p>
                                    Du 19 août au 6 septembre 2024 <br />
                                    8h30 - 12h / 13h15 – 16h30
                                </p>
                                <p>
                                    Merci de vous annoncer jusqu’au 14 juillet 2024 par e-mail à l’adresse{' '}
                                    <a href="mailto:daniela.oberlojer@heig-vd.ch">daniela.oberlojer@heig-vd.ch</a> en
                                    cas <strong>d’impossibilité de participer</strong> à ces cours préparatoires.
                                </p>
                            </>
                        ),
                    ],
                    [
                        isTic,
                        always(
                            <>
                                <p>
                                    Les unités préparatoires permettent aux nouveaux étudiants de la HEIG-VD de combler
                                    des lacunes dans certaines disciplines techniques et se déroulent du 19 août au 6
                                    septembre 2024.
                                </p>
                                <h3>Informations</h3>
                                <h4>Unités proposées</h4>
                                <ol>
                                    <li>
                                        UPSysLog (systèmes logiques) → conseillé pour préparer aux aspects de base de
                                        l'architecture des ordinateurs et des systèmes informatiques embarqués
                                    </li>
                                    <li>UPMath (mathématiques) → conseillé aux étudiant-e-s peu sûrs en maths</li>
                                    <li>
                                        UPInfo (Informatique) → conseillé pour améliorer l'aisance avec l'ordinateur et
                                        les outils de programmation
                                    </li>
                                </ol>
                                <h4>Dates et horaires</h4>
                                <p>
                                    Du <strong>lundi</strong> 19 août au <strong>vendredi</strong> 6 septembre 2024
                                    <br />
                                    Par bloc : 8h30 à 12h et 13h15 à 16h30
                                    <br />
                                    Contenu :{' '}
                                    <a href="https://intra.heig-vd.ch/departement/departement-tin/etudiants/Pages/unites-preparatoires.aspx">
                                        fiches descriptives des unités
                                    </a>
                                    <br />
                                    Organisation :{' '}
                                    <a href="https://intra.heig-vd.ch/departement/departement-tin/etudiants/Units%20prparatoires%202010/Organisation.pdf">
                                        horaire
                                    </a>
                                    <br />
                                    Nombre de périodes de cours/labo par unité : 36
                                </p>
                                <h4>Inscriptions</h4>
                                <p>
                                    Nombre de places limité : 24 à 30 selon les cours et par ordre d’inscription
                                    <br />
                                    <strong>Inscription obligatoire jusqu’au vendredi 2 août</strong> dernier délai (pas
                                    de participation sans inscription acceptée)
                                    <br />
                                    Inscription par courriel auprès de{' '}
                                    <a href="mailto:secretariat.tic@heig-vd.ch">secretariat.tic@heig-vd.ch</a>
                                </p>
                                <h4>Modalités</h4>
                                <p>
                                    Présence obligatoire pour celles et ceux dont l’inscription est acceptée
                                    <br />
                                    Ces unités ne donnent pas droit à de crédits ECTS et sont soumises à un contrôle
                                    continu (pas d’examen) dont la moyenne figurera dans le bulletin de notes
                                </p>
                                <p>
                                    Vous pouvez consulter un descriptif de chaque unité ainsi que d’autres détails{' '}
                                    <a
                                        href="http://intra.heig-vd.ch/departement/departement-tin/etudiants/Pages/unites-preparatoires.aspx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ici
                                    </a>{' '}
                                    en vous connectant avec les accès informatiques transmis.
                                </p>
                            </>
                        ),
                    ],
                    [
                        isTin,
                        always(
                            <>
                                <p>
                                    Les unités préparatoires permettent aux nouveaux étudiants de la HEIG-VD de combler
                                    des lacunes dans certaines disciplines techniques et se déroulent du{' '}
                                    <strong>19 août au 6 septembre 2024</strong>.
                                </p>
                                <h3>Informations</h3>
                                <h4>Unités proposées</h4>
                                {applyTo(
                                    student,
                                    cond([
                                        [
                                            isEte,
                                            always(
                                                <>
                                                    <ol>
                                                        <li>
                                                            UPMath (mathématiques) → conseillé aux étudiant·es peu sûrs
                                                            en maths
                                                        </li>
                                                        <li>UPElec (électricité et électronique)</li>
                                                    </ol>
                                                </>
                                            ),
                                        ],
                                        [
                                            isMtecOrSyndOrIgis,
                                            always(
                                                <>
                                                    <ol>
                                                        <li>
                                                            UPMath (mathématiques) → conseillé aux étudiant·es peu sûrs
                                                            en maths
                                                        </li>
                                                        <li>UPElec (électricité et électronique)</li>
                                                        <li>UPMeca (construction mécanique)</li>
                                                    </ol>
                                                </>
                                            ),
                                        ],
                                        [
                                            isElci,
                                            always(
                                                <>
                                                    <ol>
                                                        <li>
                                                            UPMath (mathématiques) → conseillé aux étudiant·es peu sûrs
                                                            en maths
                                                        </li>
                                                        <li>UPElec (électricité et électronique)</li>
                                                        <li>
                                                            UPSysLog (systèmes logiques) → conseillé pour préparer aux
                                                            aspects de base de l'architecture des ordinateurs et des
                                                            systèmes informatiques embarqués
                                                        </li>
                                                    </ol>
                                                </>
                                            ),
                                        ],
                                        [T, always(<> </>)],
                                    ])
                                )}

                                <h4>Dates et horaires</h4>
                                <p>
                                    Du <strong>lundi</strong> 19 août au <strong>vendredi</strong> 6 septembre 2024
                                    <br />
                                    Par bloc : 8h30 à 12h et 13h15 à 16h30
                                    <br />
                                    Contenu :{' '}
                                    <a href="https://intra.heig-vd.ch/departement/departement-tin/etudiants/Pages/unites-preparatoires.aspx">
                                        fiches descriptives des unités
                                    </a>
                                    <br />
                                    Organisation :{' '}
                                    <a href="https://intra.heig-vd.ch/departement/departement-tin/etudiants/Units%20prparatoires%202010/Organisation.pdf">
                                        horaire
                                    </a>
                                    <br />
                                    Nombre de périodes de cours/labo par unité : 36
                                </p>
                                <h4>Inscriptions</h4>
                                <p>
                                    Nombre de places limité : 24 à 30 selon les cours et par ordre d’inscription
                                    <br />
                                    <strong>Inscription obligatoire jusqu’au vendredi 2 août</strong> dernier délai (pas
                                    de participation sans inscription acceptée)
                                    <br />
                                    Inscription par courriel auprès de{' '}
                                    <a href="mailto:secretariat.tin@heig-vd.ch">secretariat.tin@heig-vd.ch</a>
                                </p>
                                <h4>Modalités</h4>
                                <p>
                                    Présence obligatoire pour celles et ceux dont l’inscription est acceptée
                                    <br />
                                    Ces unités ne donnent pas droit à de crédits ECTS et sont soumises à un contrôle
                                    continu (pas d’examen) dont la moyenne figurera dans le bulletin de notes
                                </p>
                                <p>
                                    Vous pouvez consulter un descriptif de chaque unité ainsi que d’autres détails sur
                                    la{' '}
                                    <a
                                        href="http://intra.heig-vd.ch/departement/departement-tin/etudiants/Pages/unites-preparatoires.aspx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        page intranet TIN
                                    </a>{' '}
                                    en vous connectant avec les accès informatiques transmis.
                                </p>
                            </>
                        ),
                    ],
                ])
            )}
        </div>
    )
}
