import React, { useEffect } from 'react'
import Form from './Form'
import { navigate } from '@reach/router'
import request from 'request-promise'
import ViewerMode from './ViewerMode'
import Student from './Student'
import LangAccessCodes from './LangAccessCodes'

const { REACT_APP_API_URI } = process.env

const fetchContext = (studentGuid) => {
    const options = {
        uri: `${REACT_APP_API_URI}/context/${studentGuid}`,
        json: true,
    }
    return request(options)
}

export default ({ studentGuid }) => {
    const { setForm } = Form.useContainer()
    const { setViewerMode } = ViewerMode.useContainer()
    const { setStudent } = Student.useContainer()
    const { setLangAccessCodes } = LangAccessCodes.useContainer()

    useEffect(() => {
        const run = async () => {
            const { form, student, langAccessCodes } = await fetchContext(studentGuid)
            setStudent(student)
            setForm(form)
            setLangAccessCodes(langAccessCodes)
            setViewerMode(true)
            navigate('/2024/comptes-acces')
        }
        run()
    }, [setForm, studentGuid, setStudent, setViewerMode, setLangAccessCodes])

    return <div />
}
