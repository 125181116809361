import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import { isComem } from '../lib/utils'
import Student from '../Student'

const Comem = () => (
    <>
        <h3>Configuration du PC portable</h3>
        <p>
            Tous les ordinateurs portables doivent être fonctionnels à la rentrée du 17 septembre 2024. Les personnes
            participant au cours préparatoire « Communication visuelle » devront obligatoirement avoir terminé la
            configuration de leur ordinateur AVANT le début du cours. Vous trouverez toutes les informations utiles{' '}
            <a
                href="https://intranet.heig-vd.ch/departement/departement-comem/Presentations/Rentre%CC%81e%202024%20-%20configuration%20PC.pdf"
                target="_blank"
                rel="noopener noreferrer"
            >
                ici
            </a>
            .
        </p>
    </>
)

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Ordinateur portable</h2>
            <PageStatus path={path} />
            <p>
                A la HEIG-VD, chaque étudiant-e de 1ère année doit posséder un ordinateur portable{' '}
                <strong>pour la rentrée académique du 16 septembre 2024</strong>.{' '}
            </p>
            <p>
                Les <strong>caractéristiques minimales</strong> que devra posséder votre PC portable par rapport à la
                filière choisie à la HEIG-VD, sont mentionnées sur le site Internet :{' '}
                <a href="https://heig.ch/poseidon" target="_blank" rel="noopener noreferrer">
                    https://heig.ch/poseidon
                </a>
            </p>
            <p>
                Vous avez la possibilité de choisir votre propre appareil. Toutefois, nous vous recommandons vivement de
                l'acquérir par le biais de la{' '}
                <a href="https://poseidon.epfl.ch" target="_blank" rel="noopener noreferrer">
                    plateforme Poseidon
                </a>
                , qui propose des offres validées et mises à jour régulièrement, en partenariat avec l’EPFL.
            </p>
            <p>
                Toutes nos propositions ont été négociées afin de vous{' '}
                <strong>garantir la meilleure offre possible</strong>, tant au niveau de la qualité, des services que du
                prix. Les configurations ont été particulièrement étudiées pour l'usage que vous en ferez dans notre
                école.
            </p>
            <p>
                Vous bénéficierez également d'un support optimum via le Helpdesk de la HEIG-VD, dont les coordonnées
                complètes se trouvent sur{' '}
                <a href="https://intra.heig-vd.ch" target="_blank" rel="noopener noreferrer">
                    l'intranet
                </a>
                .
            </p>
            <p>
                Le système d'exploitation standard pour suivre les cours de la HEIG-VD est{' '}
                <strong>Windows (10 ou 11)</strong>.<br />
                Pour celles et ceux qui ne possèdent pas ce système, une installation peut être effectuée par vos soins
                ou avec l’aide du Helpdesk. Vous trouverez ces informations sur{' '}
                <a href="https://intra.heig-vd.ch" target="_blank" rel="noopener noreferrer">
                    l'intranet
                </a>
                .
            </p>
            <p>Les logiciels nécessaires aux études seront en principe fournis par l'école.</p>

            {isComem(student) && <Comem />}
        </div>
    )
}
