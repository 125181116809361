import React from 'react'
import Allemand from './Allemand'
import Anglais from './Anglais'
import Equivalence from './Equivalence'
import Student from '../../Student'

export default (props) => {
    const { student } = Student.useContainer()
    const { languages: registeredLang = 'Allemand' } = student
    return (
        <>
            <h3>Informations générales</h3>
            <p>
                Le but de l’enseignement des langues est de pouvoir attester à la fin des 3 ans d’études d’un niveau de
                maîtrise B2 ou C1 en anglais ou en allemand.
            </p>
            <p>
                Pour atteindre cet objectif, les étudiant-e-s suivent des cours de langues adaptés à leur niveau. Par
                ailleurs, la HEIG-VD encourage les étudiant-e-s à se présenter à des examens de langues officiels afin
                d’obtenir un diplôme de langue internationalement reconnu. Des cours de préparation à ces diplômes sont
                proposés.
            </p>
            <h3>Connaissances préalables</h3>
            <p>
                Afin de pouvoir intégrer et suivre les cours d’anglais ou d'allemand, il est recommandé de maîtriser les
                compétences du niveau B1 dans l’une des deux langues. Il n’y a pas de cours de mise à niveau prévu.
            </p>
            <h3>Evaluation de votre niveau de langues</h3>
            <p>
                Afin d’être placé-e dans un cours de langue adapté à votre niveau, il est important que vous répondiez
                aux rubriques ci-dessous de manière autonome et individuelle, sans aide extérieure.
            </p>
            <p>Dernier délai : 30 juin</p>
            {registeredLang === 'Anglais' ? <Anglais {...props} /> : <Allemand />}
            <h3>Equivalences</h3>
            <p>Si vous le souhaitez, vous pouvez demander une équivalence dans les situations suivantes :</p>
            <ul>
                <li>
                    Vous êtes titulaire d'un diplôme ouvrant droit à une équivalence (par exemple un C1 Advanced ou un
                    BEC Higher de l’Université de Cambridge). L'attestation de niveau fournie par certains
                    établissements gymnasiaux ou autres n'est pas reconnue comme un diplôme donnant lieu à une
                    équivalence.
                </li>
                <li>
                    Vous avez suivi votre scolarité dans la langue choisie. Si vous êtes bilingue, des dispositions
                    particulières s’appliquent.
                </li>
            </ul>
            <Equivalence {...props} />
        </>
    )
}
