import React from 'react'
import App from './App'
import Form from './Form'
import Student from './Student'
import LangAccessCodes from './LangAccessCodes'
import { pipe, applyTo } from 'ramda'
import Auth from './Auth'
import ViewerMode from './ViewerMode'

const formInitialState = {
    '/comptes-acces': { read: false },
    '/evaluation-niveau-math': { read: false },
    '/inscription-unites-preparatoires': { read: false },
    '/cours-preparatoires': { read: false },
    '/verification-niveaux-langues': {
        read: false,
        requestEquivalenceForEnglish: undefined,
        requestEquivalenceForGermanOrItalian: undefined,
        requestEquivalence: undefined,
    },
    '/financement-de-vos-etudes': { readAndFilledAhesForm: false },
    '/ordinateur-portable': { read: false },
    '/code-de-savoir-vivre': {},
    '/preparation-a-l-immatriculation': { read: false },
    '/problemes-sante-specifiques': { read: false },
    '/situation-de-handicap-ou-maladie': { read: false },
    '/studios-appartements-chambres': { read: false },
    '/taxe-annuelle-etudes': { read: false },
    '/votre-carte-etudiant-e': { read: false },
    '/mobilite': { read: false },
    '/assurance-maladie-et-accident': { read: false },
    '/attestation-d-etudes': { read: false },
    '/journee-d-accueil': { read: false },
    '/rentree-et-calendrier-academique': { read: false },
    '/guide-de-l-etudiant-e': { read: false },
    '/horaire-des-cours': { read: false },
    '/renonciation': { read: false },
    '/demande-equivalence': {
        read: false,
        active: false,
    },
    '/cours-marketing': { read: false },
}

const studentInitialState = {
    filiere: 'COMEM+',
    firstName: 'n/a',
    lastName: 'n/a',
    mode: 'n/a',
    orientation: 'n/a',
    departement: 'n/a',
    languages: 'Anglais',
}

const langAccessCodesInitialState = {}

const withStudentProvider = (studentInitialState) => (Component) => (props) =>
    (
        <Student.Provider initialState={studentInitialState}>
            <Component {...props} />
        </Student.Provider>
    )
const withFormProvider = (formInitialState) => (Component) => (props) =>
    (
        <Form.Provider initialState={formInitialState}>
            <Component {...props} />
        </Form.Provider>
    )

const withLangAccessCodesProvider = (langAccessCodesInitialState) => (Component) => (props) =>
    (
        <LangAccessCodes.Provider initialState={langAccessCodesInitialState}>
            <Component {...props} />
        </LangAccessCodes.Provider>
    )

const withAuth = (Component) => (props) =>
    (
        <Auth.Provider>
            <Component {...props} />
        </Auth.Provider>
    )

const withViewerMode = (Component) => (props) =>
    (
        <ViewerMode.Provider>
            <Component {...props} />
        </ViewerMode.Provider>
    )

export default applyTo(
    App,
    pipe(
        withAuth,
        withStudentProvider(studentInitialState),
        withFormProvider(formInitialState),
        withLangAccessCodesProvider(langAccessCodesInitialState),
        withViewerMode
    )
)
