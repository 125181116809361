import React from 'react'
import styles from './Callback.module.css'

const Callback = () => (
    <div className={styles['callback-wrapper']}>
        <div className={styles['lds-hourglass']} />
    </div>
)

export default Callback
