import React from 'react'
import { Radio, FormControlLabel } from '@material-ui/core'

export default ({ styles, onChange, form = {} }) => {
    const { requestEquivalence = false } = form
    return (
        <>
            <p>Souhaitez-vous demander une équivalence pour une des langues ?</p>
            <div className={styles.choicesWrapper}>
                <div className={styles.choices}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={requestEquivalence !== undefined && requestEquivalence}
                                onClick={onChange({ key: 'requestEquivalence', value: true })}
                            />
                        }
                        label="Oui"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={requestEquivalence !== undefined && !requestEquivalence}
                                onClick={onChange({ key: 'requestEquivalence', value: false })}
                            />
                        }
                        label="Non"
                    />
                </div>
            </div>
            {requestEquivalence && (
                <ul>
                    <li>
                        <p>
                            Effectuez une demande d'équivalence (délai: 30 juin) accompagnée d'un scan des justificatifs
                            (diplômes) prouvant le niveau atteint :
                        </p>
                        <a href="https://equivalences.heig-vd.ch" target="_blank" rel="noopener noreferrer">
                            Demandes d'équivalences
                        </a>
                    </li>
                    <li>
                        <p>
                            Afin de statuer sur votre demande d'équivalence, il est impératif de{' '}
                            <strong>présenter l'original de votre justificatif</strong> au secrétariat du Centre
                            Formation de Base, Avenue des Sports 20 à Yverdon-les-Bains pour validation.
                        </p>
                        <p>
                            Le Centre Formation de Base de la HEIG-VD étudiera votre demande. Il vous communiquera sa
                            décision au plus tard lors de la première semaine de la rentrée.
                        </p>
                        <p>
                            En cas d'acceptation d'une équivalence vous ne suivrez alors pas l'unité et n'obtiendrez pas
                            de note. La note finale du module sera calculée sur la base des autres résultats.
                        </p>
                        <p>
                            <strong>
                                Tant que l'équivalence n'a pas été confirmée, la présence au cours reste obligatoire
                            </strong>
                        </p>
                    </li>
                </ul>
            )}
        </>
    )
}
