import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import pageStatusStyles from '../PageStatus.module.css'
import { Checkbox, FormControlLabel, MobileStepper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import State from '../State'
import Form from '../Form'
import Auth from '../Auth'
import request from 'request-promise'
import ViewerMode from '../ViewerMode'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { collect } from '../lib/utils'
import SwipeableViews from 'react-swipeable-views'

import Step1 from './CodeOfConductSteps/Step1'
import Step2 from './CodeOfConductSteps/Step2'
import Step3 from './CodeOfConductSteps/Step3'
import Step4 from './CodeOfConductSteps/Step4'
import Step5 from './CodeOfConductSteps/Step5'
import Step6 from './CodeOfConductSteps/Step6'

const { REACT_APP_API_URI } = process.env

const onAccept =
    ({ setAttributeWithPath, token }) =>
    async (e) => {
        const accepted = e.target.checked
        setAttributeWithPath('accepted', accepted)
        request({
            uri: `${REACT_APP_API_URI}/code-of-conduct`,
            method: 'POST',
            body: { accepted },
            json: true,
            headers: { authorization: `Bearer ${token}` },
        })
    }
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    checkbox: {
        marginLeft: 24,
        marginBottom: 48,
    },
})

// const Step = ({ activeStep = 0, ...props }) => {
//     const InnerStep = getStepComponent(activeStep)
//     return <InnerStep {...props} />
// }

const handleNext =
    ({ setActiveStep }) =>
    () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

const handleBack =
    ({ setActiveStep }) =>
    () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

export default ({ path }) => {
    const { form, setAttribute } = Form.useContainer()
    const { token } = Auth.useContainer()
    const { accepted = false } = form[path] || {}
    const { viewerMode } = ViewerMode.useContainer()
    const [activeStep, setActiveStep] = React.useState(0)
    const styles = useStyles()

    return (
        <div className={baseStyles.root}>
            <h2>Code de savoir-vivre</h2>
            <PageStatus path={path} showDefaultCheckbox={false}>
                <p className={collect(pageStatusStyles.title, pageStatusStyles.titleNoMargin)}>
                    Statut des actions à entreprendre
                </p>
                <FormControlLabel
                    control={<State done={accepted} />}
                    label={accepted ? 'Code de savoir vivre accepté' : 'Code de savoir vivre pas encore accepté'}
                    className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                />
            </PageStatus>
            <SwipeableViews index={activeStep}>
                <Step1 />
                <Step2 />
                <Step3 />
                <Step4 />
                <Step5 />
                <Step6>
                    <div className={styles.checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={accepted}
                                    disabled={viewerMode}
                                    onChange={onAccept({
                                        setAttributeWithPath: setAttribute(path),
                                        token,
                                    })}
                                />
                            }
                            label="J'ai lu et j'accepte de me conformer au code de savoir-vivre de la HEIG-VD"
                        />
                    </div>
                </Step6>
            </SwipeableViews>
            <MobileStepper
                className={styles.root}
                variant="dots"
                steps={6}
                position="static"
                activeStep={activeStep}
                backButton={
                    <Button size="small" disabled={activeStep === 0} onClick={handleBack({ setActiveStep })}>
                        <KeyboardArrowLeft />
                        Précédent
                    </Button>
                }
                nextButton={
                    <Button size="small" disabled={activeStep === 5} onClick={handleNext({ setActiveStep })}>
                        Suivant
                        <KeyboardArrowRight />
                    </Button>
                }
            />
        </div>
    )
}
