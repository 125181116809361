import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Guide de l’étudiant·e</h2>
            <PageStatus path={path} />
            <p>
                Ce guide est une source de renseignements importants. Il est mis à jour chaque année. Nous vous
                conseillons de le lire attentivement.
            </p>
            <p>
                Consulter le{' '}
                <a href="https://heig.ch/guide-etudiant" target="_blank" rel="noopener noreferrer">
                    Guide de l'étudiant·e
                </a>
                .
            </p>
        </div>
    )
}
