import React from 'react'
import styles from './State.module.css'

export default ({ done }) => (
    <div className={styles.root}>
        <svg viewBox="0 0 100 100" className={styles.svg}>
            {done ? (
                <circle style={{ fill: '#66bb6a' }} cx={50} cy={50} r={45} />
            ) : (
                <circle style={{ fill: '#ff0000' }} cx={50} cy={50} r={45} />
            )}
        </svg>
    </div>
)
