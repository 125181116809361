import { FormControlLabel, makeStyles } from '@material-ui/core'
import React from 'react'
import request from 'request-promise'
import Auth from '../Auth'
import Form from '../Form'
import PageStatus from '../PageStatus'
import pageStatusStyles from '../PageStatus.module.css'
import State from '../State'
import Student from '../Student'
import { collect, isHeg, isTicOrComem, isTinOrEcg } from '../lib/utils'
import baseStyles from './Base.module.css'
import HegComponent from './Lang/Heg.js'
import TicOrComemComponent from './Lang/TicOrComem.js'
import TinOrEcgComponent from './Lang/TinOrEcg.js'

const { REACT_APP_API_URI } = process.env

// #region Styles

const useStyles = makeStyles({
    root: {
        '& ul': {
            marginTop: '2rem',
        },
        '& li': {
            marginBottom: '2rem',
        },
        '& button': {
            display: 'block',
            marginTop: '1rem',
        },
    },
    choicesWrapper: {
        border: 'solid 1px #ddd',
        padding: '1.5rem',
        marginTop: '3rem',
        marginBottom: '4rem',
    },
    choices: {
        display: 'flex',
        flexDirection: 'row',
        '& > span': {
            minWidth: '30%',
            alignSelf: 'center',
        },
        '& label': {
            marginRight: '3rem',
            '&:last-of-type': {
                marginRight: 0,
            },
        },
        '& span': {
            fontSize: 'inherit',
        },
    },
    link: {
        display: 'block',
        marginTop: '1rem',
    },
    langAccessCodes: {
        marginTop: '1rem !important',
        '& li': {
            marginBottom: 0,
        },
        '& span': {
            display: 'inline-block',
            width: 150,
        },
    },
    instructions: {
        background: 'transparent',
        '& .MuiExpansionPanelDetails-root': {
            display: 'block',
        },
        '& li': {
            marginBottom: '.5rem',
        },
        '& li:last-of-type': {
            marginBottom: '2rem',
        },
    },
})

// #endregion

const updateForm =
    ({ token }) =>
    async (form) =>
        request({
            uri: `${REACT_APP_API_URI}/forms`,
            method: 'POST',
            body: form,
            json: true,
            headers: { authorization: `Bearer ${token}` },
        })

const getPageStatus = (student, form) => {
    const { requestEquivalence = undefined } = form || {}

    if (isHeg(student)) {
        return requestEquivalence === undefined
            ? {
                  done: false,
                  label: "Vous n'avez pas fait votre choix pour les demandes d'équivalence",
              }
            : { done: true, label: "Vous avez effectué votre choix pour les demandes d'équivalence" }
    } else {
        return requestEquivalence === undefined
            ? {
                  done: false,
                  label: "Vous n'avez pas fait votre choix pour la demande d'équivalence",
              }
            : { done: true, label: "Vous avez effectué votre choix pour la demande d'équivalence" }
    }
}

export default ({ path }) => {
    const styles = useStyles()
    const { student } = Student.useContainer()
    const { form, setForm } = Form.useContainer()
    const { token } = Auth.useContainer()

    const onChange =
        ({ key, value }) =>
        (e) => {
            const updatedForm = { ...form, [path]: { ...form[path], [key]: value } }
            setForm(updatedForm)
            updateForm({ token })(updatedForm)
        }

    const { done = false, label } = form && getPageStatus(student, form[path])

    const heg = isHeg(student)
    const ticOrComem = isTicOrComem(student)
    const tinOrEcg = isTinOrEcg(student)

    const props = {
        styles,
        form: form[path],
        onChange,
    }

    return (
        <div className={`${baseStyles.root} ${styles.root}`}>
            <h2>Vérification des niveaux de langues</h2>
            <PageStatus path={path}>
                <p className={pageStatusStyles.title}>Statut des actions à entreprendre</p>
                <FormControlLabel
                    control={<State done={done} />}
                    label={label}
                    className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                />
            </PageStatus>
            {heg && <HegComponent {...props} />}
            {ticOrComem && <TicOrComemComponent {...props} />}
            {tinOrEcg && <TinOrEcgComponent {...props} />}
        </div>
    )
}
