import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        background: '#eb4d4b',
        textAlign: 'center',
        padding: '12px 0',
        textTransform: 'uppercase',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
})

export default () => {
    const styles = useStyles()
    return <div className={styles.root}>Read only</div>
}
