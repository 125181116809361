import React from 'react'
import baseStyles from './Base.module.css'
import Student from '../Student'
import { applyTo, T, cond, always } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import { isHeg, isComem, isTicTpEe, isTinTpEe } from '../lib/utils'

const useStyles = makeStyles({
    title: {
        marginBottom: 64,
        fontSize: 32,
    },
    warning: {
        border: 'solid 2px #ffe58f',
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 64,
    },
    bellIcon: {
        marginBottom: 16,
    },
})

const BellIcon = (props) => (
    <svg {...props} viewBox="0 0 32 32" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 25h4v2h-4zM22.979 22v-8.01c0-3.516-2.599-6.418-5.979-6.908V6h-2v1.075a6.967 6.967 0 0 0-6 6.915V22H7v2h18v-2h-2.021zM21 22H10.979v-8.01A4.996 4.996 0 0 1 15.969 9h.021C18.741 9 21 11.238 21 13.99V22z" />
    </svg>
)

export default () => {
    const styles = useStyles()
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2 className={styles.title}>Bienvenue sur la portail de préparation à la rentrée 2024</h2>
            <div className={styles.warning}>
                <BellIcon width={48} className={styles.bellIcon} />
                Le 16 septembre 2024 étant un jour férié, le premier jour de cours est donc le 17 septembre 2024.
            </div>
            <p>
                Cette plateforme regroupe l'ensemble des informations importantes que vous devez lire et les documents
                que vous devez obligatoirement remplir pour que vous soyez autorisé·e à débuter vos études.
            </p>
            <p>
                Vous devez également préparer votre immatriculation en nous transmettant, dans le délai imparti, les
                diplômes et relevés de notes qui vous donnent l'accès à notre École.
            </p>
            <p>
                <strong>
                    Afin de préparer au mieux votre rentrée académique et vos études, nous vous conseillons de lire
                    attentivement toutes les rubriques de ce portail.
                </strong>
            </p>
            <p>
                Nous tenons à vous spécifier qu'aucune correspondance ne sera expédiée par courrier postal pour la
                rentrée académique.
            </p>

            {applyTo(
                student,
                cond([
                    [
                        isHeg,
                        always(
                            <p>
                                Nous restons à votre disposition pour toutes questions au{' '}
                                <a href="tel:+41245577602">024 557 76 02</a>.
                            </p>
                        ),
                    ],
                    [
                        isComem,
                        always(
                            <p>
                                Nous restons à votre disposition pour toutes questions au{' '}
                                <a href="tel:+41245577604">024 557 76 04</a>.
                            </p>
                        ),
                    ],
                    [
                        isTicTpEe,
                        always(
                            <p>
                                Nous restons à votre disposition pour toutes questions au{' '}
                                <a href="tel:+41245576492">024 557 64 92</a>.
                            </p>
                        ),
                    ],
                    [
                        isTinTpEe,
                        always(
                            <p>
                                Nous restons à votre disposition pour toutes questions au{' '}
                                <a href="tel:+41245576492">024 557 64 92</a>.
                            </p>
                        ),
                    ],
                    [
                        T,
                        always(
                            <p>
                                Nous restons à votre disposition pour toutes questions au{' '}
                                <a href="tel:+41245576408">024 557 64 08</a>.
                            </p>
                        ),
                    ],
                ])
            )}
        </div>
    )
}
