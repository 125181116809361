import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => (
    <div className={baseStyles.root}>
        <h2>Inscription aux cours préparatoires</h2>
        <PageStatus path={path} />
        <p>
            <strong>Consolidation des bases en mathématiques et/ou comptabilité.</strong>
        </p>
        <p>
            Afin de vous soutenir dans la réussite dans votre formation, deux cours préparatoires sont proposés aux
            futur·e·s étudiant·es du département HEG, l’un en mathématiques, l’autre en comptabilité.
        </p>
        <p>
            Si vous pensez que ces matières pourraient s’avérer difficiles pour vous, nous vous recommandons vivement de
            vous y inscrire d’ici <strong>au vendredi 7 juin</strong> au moyen de{' '}
            <a
                href="https://enquete.heig-vd.ch/SurveyServer/s/HEG_Gestion_Cours/HEG_Cours_preparatoiresIQ3/questionnaire.htm"
                target="_blank"
                rel="noopener noreferrer"
            >
                ce formulaire
            </a>
            .
        </p>
    </div>
)
