import React from 'react'

export default () => (
    <div>
        <p>
            La HEIG-VD met à disposition des étudiant·es et du personnel des <strong>espaces</strong>, du{' '}
            <strong>matériel</strong> et du <strong>mobilier</strong> de qualité. L’étudiant·e est notamment tenu·e de :
        </p>
        <ul>
            <li>S’abstenir de fumer dans les bâtiments.</li>
            <li>
                Ne consommer ni nourriture ni boisson alcoolisée dans les salles de cours, laboratoires ou salles
                d’étude ; boire de l’eau est autorisé, excepté dans les locaux où cela est explicitement interdit pour
                des raisons de sécurité.
            </li>
            <li>
                Respecter la disposition des salles de cours et, en cas de déplacement du mobilier, le remettre dans la
                disposition initiale à la fin du cours.
            </li>
            <li>
                Ne pas sortir de mobilier ou matériel des salles de cours ou d’étude, ni transférer de mobilier ou
                matériel dans une autre salle.
            </li>
            <li>Garder les lieux propres (salles de classe, espaces de restauration, sanitaires, etc.)</li>
            <li>Laisser les portes, qui doivent l’être, fermées (ne pas caler ces portes).</li>
            <li>
                Utiliser avec soin le matériel mis à disposition, et uniquement pour l’usage auquel il est destiné (voir
                notamment la Directive informatique pour les étudiant·es).
            </li>
            <li>Se conformer aux règles d’emprunt et d’utilisation en libre-service du matériel.</li>
            <li>
                Signaler à l’enseignant·e, l’assistant·e ou au personnel technique de son département tout matériel ou
                appareil défectueux.
            </li>
            <li>Stationner exclusivement dans les cases autorisées.</li>
        </ul>
    </div>
)
