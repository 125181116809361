import React from 'react'
import withRoot from './withRoot'
import { Router } from '@reach/router'
import Header from './Header'
import Name from './Name'
import TOC from './TOC'
import { Container, Grid } from '@material-ui/core'
import Home from './pages/Home'
import styles from './App.module.css'

import ComptesAcces from './pages/ComptesAcces'
import EvaluationNiveauMath from './pages/EvaluationNiveauMath'
import InscriptionUnitesPreparatoires from './pages/InscriptionUnitesPreparatoires'
import CoursPreparatoires from './pages/CoursPreparatoires'
import VerificationNiveauxLangues from './pages/VerificationNiveauxLangues'
import CoursMarketing from './pages/CoursMarketing'

import OrdinateurPortable from './pages/OrdinateurPortable'
import CodeSavoirVivre from './pages/CodeSavoirVivre'
import FinancementDeVosEtudes from './pages/FinancementDeVosEtudes'
import PreparationImmatriculation from './pages/PreparationImmatriculation'

import TaxeAnnuelleEtudes from './pages/TaxeAnnuelleEtudes'
import CarteEtudiant from './pages/CarteEtudiant'
import ProblemesSanteSpecifiques from './pages/ProblemeSanteSpecifiques'
import SituationDeHandicapOuMaladie from './pages/SituationDeHandicapOuMaladie'
import StudiosAppartementsChambres from './pages/StudiosAppartementsChambres'
import Mobilite from './pages/Mobilite'
import AssuranceMaladieEtAccident from './pages/AssuranceMaladieEtAccident'
import AttestationEtudesEtCarteEtudiant from './pages/AttestationEtudesEtCarteEtudiant'
import JourneeAccueil from './pages/JourneeAccueil'
import RentreeAcademiqueEtCalendrierAcademique from './pages/RentreeAcademiqueEtCalendrierAcademique'
import GuideEtudiant from './pages/GuideEtudiant'
import HoraireDesCours from './pages/HoraireDesCours'
import Renonciation from './pages/Renonciation'
import DemandeEquivalence from './pages/DemandeEquivalence'

import Load from './Load'
import Unauthenticated from './pages/Unauthenticated'
import Auth from './Auth'
import ViewerMode from './ViewerMode'
import ViewerModeBanner from './ViewerModeBanner'
import Unauthorized from './Unauthorized'
import DebugWrapper from './DebugWrapper'
import Callback from './pages/Callback'
import Student from './Student'

import { useInternetExplorer, InternetExplorer } from './InternetExplorer'

const App = () => {
    const isIE = useInternetExplorer()
    if (isIE) return <InternetExplorer />

    const { authenticated } = Auth.useContainer()

    // const authenticated = true

    const { viewerMode } = ViewerMode.useContainer()
    const { student } = Student.useContainer()
    console.log(student)

    return (
        <>
            {authenticated || viewerMode ? (
                <>
                    <Header />
                    {student.canDebug && <DebugWrapper />}
                    <Name />
                    {viewerMode && <ViewerModeBanner />}
                    <Container className={styles.container}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <TOC
                                    disabled={
                                        !(
                                            student.passwordHasBeenModified &&
                                            student.eduIDIsLinked &&
                                            student.mfaConfigured
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Router primary={false} basepath="/2024">
                                    <Home default />

                                    <ComptesAcces path="/comptes-acces" />
                                    <EvaluationNiveauMath path="/evaluation-niveau-math" />
                                    <InscriptionUnitesPreparatoires path="/inscription-unites-preparatoires" />
                                    <CoursPreparatoires path="/cours-preparatoires" />
                                    <VerificationNiveauxLangues path="/verification-niveaux-langues" />
                                    <CoursMarketing path="/cours-marketing" />

                                    <OrdinateurPortable path="/ordinateur-portable" />
                                    <CodeSavoirVivre path="/code-de-savoir-vivre" />
                                    <FinancementDeVosEtudes path="/financement-de-vos-etudes" />
                                    <PreparationImmatriculation path="/preparation-a-l-immatriculation" />

                                    <TaxeAnnuelleEtudes path="/taxe-annuelle-etudes" />
                                    <CarteEtudiant path="/votre-carte-etudiant-e" />
                                    <ProblemesSanteSpecifiques path="/problemes-sante-specifiques" />
                                    <SituationDeHandicapOuMaladie path="/situation-de-handicap-ou-maladie" />
                                    <StudiosAppartementsChambres path="/studios-appartements-chambres" />
                                    <Mobilite path="/mobilite" />
                                    <AssuranceMaladieEtAccident path="/assurance-maladie-et-accident" />
                                    <AttestationEtudesEtCarteEtudiant path="/attestation-d-etudes" />
                                    <JourneeAccueil path="/journee-d-accueil" />
                                    <RentreeAcademiqueEtCalendrierAcademique path="/rentree-et-calendrier-academique" />
                                    <GuideEtudiant path="/guide-de-l-etudiant-e" />
                                    <HoraireDesCours path="/horaire-des-cours" />
                                    <Renonciation path="/renonciation" />

                                    <DemandeEquivalence path="/demande-equivalence" />
                                </Router>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            ) : (
                <Router primary={false}>
                    <Unauthenticated default />
                    <Callback path="/callback" />
                    <Load path="/2024/load/:studentGuid" />
                    <Unauthorized path="/unauthorized" />
                </Router>
            )}
        </>
    )
}

export default withRoot(App)
