import { FormControlLabel } from '@material-ui/core'
import { isBefore } from 'date-fns'
import React from 'react'
import { collect } from '../lib/utils'
import PageStatus from '../PageStatus'
import pageStatusStyles from '../PageStatus.module.css'
import State from '../State'
import Student from '../Student'
import baseStyles from './Base.module.css'

const BeforeEmbargo = () => (
    <>
        <p>
            La taxe annuelle d'études demandée ne couvre qu'une partie infime de vos études. Le{' '}
            <strong>financement du coût de vos études</strong> est assumé à la fois par la Confédération et par les
            cantons.
        </p>
        <p>
            Un formulaire que vous devrez obligatoirement remplir permettra de déterminer quel canton financera vos
            études. Il sera disponible sur cette page dès le jeudi 1<sup>er</sup> juin 2024.
        </p>
        <p>A ce sujet, un e-mail vous sera expédié sur votre adresse e-mail privée.</p>
    </>
)

const AfterEmbargo = () => {
    const { student } = Student.useContainer()
    return (
        <>
            <p>
                La taxe annuelle d'études demandée ne couvre qu'une partie infime de vos études. Le financement du coût
                de vos études est assumé à la fois par la Confédération et par les cantons. La répartition de ce coût
                entre les cantons est réglementée par l'Accord Intercantonal sur les Hautes Écoles Spécialisées (AHES).
                La contribution étant fixée en fonction de l'origine des étudiant·es, il vous est dès lors obligatoire
                de remplir le formulaire suivant et de vous conformer scrupuleusement aux instructions affichées :{' '}
                <a
                    href="https://age.hes-so.ch/imoniteur_AGEP/!formInscrs.connection?ww_c_formulaire=AHES&ww_n_login=2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Formulaire AHES
                </a>
            </p>
            <p>
                Pour les personnes domiciliées à l'étranger
                <br />
                Ce formulaire sera à remplir lorsque vous serez arrivé·e en Suisse. Merci de ne pas en tenir compte tant
                que vous êtes encore domicilié·e dans votre pays.
            </p>
            <h3>Information importante</h3>
            <ul>
                <li>
                    Le formulaire accompagné du ou des document·s demandé·s doivent impérativement être validés et
                    téléchargés de suite. Votre admission à la HEIG-VD sera validée après vérification électronique
                    formelle de vos informations par le Secrétariat académique. Votre immatriculation doit également
                    être terminée et validée. D'autres conditions d'admission demeurent réservées.
                </li>
            </ul>
            <h3>Informations complémentaires importantes concernant les documents officiels à joindre</h3>
            <ul>
                <li>
                    Tous les documents doivent être délivrés par un service administratif agréé (se conformer aux
                    instructions fournies).
                </li>
                <li>
                    Les étudiant·es concerné·e·s par la rubrique E1 doivent télécharger une attestation de domicile au
                    nom des parents (une attestation au nom de l'étudiant-e sera refusée). Si vous avez mentionné le nom
                    de votre père sur le formulaire, l'attestation doit être demandée, au contrôle des habitants, au nom
                    de votre père. Il en va de même si vous avez inscrit le nom de votre mère.
                </li>
                <li>Uniquement les attestations datées à partir de juin 2024 seront acceptées.</li>
            </ul>
            {student.ahesFin && (
                <>
                    <p>Vos documents AHES ont été validés par le Secrétariat académique de la HEIG-VD.</p>
                    <p>
                        Nous tenons à vous remercier pour le temps que vous avez consacré à cette démarche importante
                        pour notre École.
                    </p>
                </>
            )}
        </>
    )
}

export default ({ path }) => {
    const {
        student: { ahesFin },
    } = Student.useContainer()

    const embargo = isBefore(new Date(), new Date(2023, 5, 1))

    return (
        <div className={baseStyles.root}>
            <h2>
                Financement de vos études –<br />
                Formulaire AHES à remplir obligatoirement avant le <span className={baseStyles.nlb}>14 juillet 2024</span>
            </h2>
            <PageStatus
                path={path}
                attribute="readAndFilledAhesForm"
                label="J'ai pris connaissance du contenu de la page et rempli le formulaire AHES selon les instructions indiquées"
            >
                <p className={pageStatusStyles.title}>Statut des actions à entreprendre</p>
                <FormControlLabel
                    control={<State done={ahesFin} />}
                    label={ahesFin ? 'Formulaire AHES validé' : 'Formulaire AHES pas encore validé'}
                    className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                />
                <div className={pageStatusStyles.info}>
                    <p>
                        Veuillez noter que la vérification par le Secrétariat académique peut prendre de quelques jours
                        à plusieurs semaines.
                    </p>
                </div>
            </PageStatus>
            {embargo ? <BeforeEmbargo /> : <AfterEmbargo />}
        </div>
    )
}
