import { createContainer } from 'unstated-next'
import { useState } from 'react'
import { assocPath } from 'ramda'

function useForm(initialState) {
    const [form, setForm] = useState(initialState)
    const setAttribute = path => (key, value) => {
        const updatedForm = assocPath([path, key], value, form)
        setForm(updatedForm)
        return updatedForm
    }
    return { form, setForm, setAttribute }
}

export default createContainer(useForm)
