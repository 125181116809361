import { allPass, and, anyPass, converge, equals, identity, isNil, join, pipe, prop, trim, unapply } from 'ramda'

export const isEte = pipe(prop('filiere'), equals('Energie et techniques environnementales'))
export const isElci = pipe(prop('filiere'), equals('Génie électrique'))
export const isMtec = pipe(prop('filiere'), equals('Microtechniques'))
export const isSynd = pipe(prop('filiere'), equals('Systèmes industriels'))
export const isIgis = pipe(prop('filiere'), equals('Ingénierie et gestion industrielles'))

export const isGenieTerritorial = pipe(prop('filiere'), equals('Génie territorial'))

export const isMtecOrSyndOrIgis = anyPass([isMtec, isSynd, isIgis])

export const isGec = pipe(prop('filiere'), equals('Génie électrique'))

// export const isIgi = pipe(prop('filiere'), equals('Ingénierie et gestion industrielles'))

export const isTic = pipe(prop('departement'), equals('TIC'))

export const isTin = pipe(prop('departement'), equals('TIN'))

export const isEcg = pipe(prop('departement'), equals('EC+G'))

export const isComem = pipe(prop('departement'), equals('COMEM+'))

export const isHeg = pipe(prop('departement'), equals('HEG'))

export const isPt = pipe(prop('mode'), equals('Plein temps'))

export const isTp = pipe(prop('mode'), equals('Temps partiel'))

export const isEe = pipe(prop('mode'), equals('En emploi'))

export const isTicTpEe = converge(and, [anyPass([isTic]), anyPass([isTp, isEe])])

export const isTinTpEe = converge(and, [anyPass([isTin]), anyPass([isTp, isEe])])

export const isTicTinEcgPt = converge(and, [anyPass([isTic, isTin, isEcg]), isPt])

export const isTicTinEcg = anyPass([isTic, isTin, isEcg])

export const isHegComem = anyPass([isHeg, isComem])

export const isTicTinEcgComem = anyPass([isTic, isTin, isEcg, isComem])

export const collect = pipe(unapply(identity), join(' '), trim)

export const isGerman = pipe(prop('languages'), equals('Allemand'))

export const isEnglish = pipe(prop('languages'), equals('Anglais'))

export const isItalian = pipe(prop('languages'), equals('Italien'))

export const hasNoLanguage = pipe(prop('languages'), isNil)

export const journeeAccueilTocFilter = anyPass([
    allPass([isEcg, isGerman]),
    allPass([isEcg, isEnglish]),
    allPass([isTin, isGerman]),
    allPass([isTin, isEnglish]),
    allPass([isTic, isGerman]),
    allPass([isTic, isEnglish]),
    allPass([isComem, isGerman]),
    allPass([isComem, isEnglish]),
    isHeg,
    isGenieTerritorial
])

export const inscriptionUnitesPreparatoiresTocFilter = anyPass([isTic, isTin, isComem, isEcg])

export const horaireDesCoursTocFilter = anyPass([isTicTinEcg, isComem, isHeg])

export const verificationNiveauMathTocFilter = anyPass([isEcg, isTic, isTin, isComem, isHeg])

export const coursPreparatoiresTocFilter = anyPass([isHeg])

export const isTicTinEcgComemNoLanguage = converge(and, [anyPass([isTic, isTin, isEcg, isComem]), hasNoLanguage])

export const isTicTinEcgComemEnglish = converge(and, [anyPass([isTic, isTin, isEcg, isComem]), isEnglish])

export const isTicTinEcgComemGerman = converge(and, [anyPass([isTic, isTin, isEcg, isComem]), isGerman])

export const isTicPt = allPass([isTic, isPt])

export const isTicEeTp = converge(and, [isTic, anyPass([isTp, isEe])])

export const isEcgPtGerman = allPass([isEcg, isPt, isGerman])

export const isEcgEeTpGerman = converge(and, [anyPass([isTp, isEe]), isGerman])

export const isEcgPtEnglish = allPass([isEcg, isPt, isEnglish])

export const isEcgEeTpEnglish = converge(and, [anyPass([isTp, isEe]), isEnglish])

export const isTicOrComem = anyPass([isTic, isComem])

export const isTinOrEcg = anyPass([isTin, isEcg])
