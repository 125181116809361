import React from 'react'
import request from 'request-promise'
import LangAccessCodes from '../../LangAccessCodes'
import Auth from '../../Auth'
import Student from '../../Student'
import { Button } from '@material-ui/core'

const { REACT_APP_API_URI } = process.env

export default ({ styles }) => {
    const { langAccessCodes = {}, setLangAccessCodes } = LangAccessCodes.useContainer()
    const { token } = Auth.useContainer()
    const {
        student: { emailEcole },
    } = Student.useContainer()
    const [fetchingLangAccessCodes, setFetchingLangAccessCodes] = React.useState({ oxford: false, altissia: false })
    const requestLangAccessCode = async (lang) => {
        const key = 'oxford' // = lang === 'en' ? 'oxford' : 'altissia'

        setFetchingLangAccessCodes({ ...fetchingLangAccessCodes, [key]: true })

        const result = await request({
            uri: `${REACT_APP_API_URI}/fetch-codes`,
            method: 'POST',
            body: { lang },
            json: true,
            headers: { authorization: `Bearer ${token}` },
        })

        setLangAccessCodes({
            ...langAccessCodes,
            [key]: { ...result },
        })
    }

    return langAccessCodes.oxford ? (
        <>
            <p>
                Lien du test :{' '}
                <a href="https://www.oxfordenglishtesting.com/login" target="_blank" rel="noopener noreferrer">
                    https://www.oxfordenglishtesting.com/login
                </a>
            </p>
            <ul className={styles.langAccessCodes}>
                <li>
                    <span>Student name:</span> {langAccessCodes.oxford.studentName}
                </li>
                <li>
                    <span>Username:</span> {langAccessCodes.oxford.username}
                </li>
                <li>
                    <span>Password:</span> {langAccessCodes.oxford.password}
                </li>
                <li>
                    <span>Organisation ID:</span> {langAccessCodes.oxford.orgID}
                </li>
            </ul>
            <p>
                <strong>Important :</strong> après l'authentification, il vous sera demandé de compléter vos informations personnelles (First name, Last name, Email). À cette étape, il faudra impérativement utiliser votre
                adresse e-mail École ({emailEcole}).
            </p>
        </>
    ) : (
        <Button
            variant="contained"
            size="small"
            onClick={() => requestLangAccessCode('en')}
            disabled={fetchingLangAccessCodes.oxford}
        >
            Obtenir un accès
        </Button>
    )
}
