import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import { isHeg } from '../lib/utils'
import { applyTo, cond, always, T } from 'ramda'
import Student from '../Student'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Horaire des cours</h2>
            <PageStatus path={path} />
            {applyTo(
                student,
                cond([
                    [
                        isHeg,
                        always(
                            <>
                                <p>La présence aux cours est obligatoire.</p>
                                <p>
                                    Votre classe, ainsi que votre horaire vous sera communiqué par email, sur votre
                                    adresse email de l’École.
                                </p>
                                <p>Pour les <strong>étudiant·es en emploi</strong>, les <a
                                        href="https://intra.heig-vd.ch/departement/departement-heg/filiere/administration-filiere/horaires-et-inscriptions/Documents/Jours%20de%20cours%20%C3%A9tudiants%20EE.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        jours de cours
                                    </a> en première année sont :</p>
                                <ul>
                                    <li>Lundi après-midi et soir</li>
                                    <li>Mercredi matin et après-midi</li>
                                    <li>Samedi matin</li>
                                </ul>
                            </>
                        ),
                    ],
                    [
                        T,
                        always(
                            <>
                                <p>La présence aux cours est obligatoire.</p>
                                <p>
                                    Vous trouverez votre horaire personnalisé, <strong>début septembre</strong> uniquement, sous le lien:{' '}
                                    <a
                                        href="https://gaps.heig-vd.ch/consultation/horaires/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://gaps.heig-vd.ch/consultation/horaires
                                    </a>
                                    . Si l’horaire ne s’affiche pas,
                                    sélectionner « S1 24-25 ».
                                </p>
                                <p>Pour les <strong>étudiant·es à temps partiel et en emploi</strong>, Les cours ont lieu sur deux après-midis et soirées et une journée complète, soit, pour la 1ère année, le lundi et le mardi de 13h15 à 21h35 et le jeudi de 8h30 à 12h00 et de 13h15 à 18h05. Puis, dès la 2e année, les jours de cours peuvent changer à chaque semestre.</p>
                            </>
                        ),
                    ],
                ])
            )}
        </div>
    )
}
