import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import Student from '../Student'
import { isComem, isHeg } from '../lib/utils'
import { applyTo, always, cond, T, allPass } from 'ramda'
import './JourneeAccueil.css'
import { Link } from '@reach/router'

/*
const EcgGerman = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h15 – 11h30 - G01 - Informations de votre chef de département et de votre responsable de filière</li>
            <li>13h00 – 13h50 - G01 - Informations au sujet de l'enseignement de l'Allemand</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
        <ul>
            <li>
                09h30 - 10h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                11h30 - 12h45 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                15h30 - 17h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
        </ul>
    </>
)

const EcgEnglish = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h15 – 11h30 - G01 - Informations de votre chef de département et de votre responsable de filière</li>
            <li>13h00 – 13h50 - Aula - Informations au sujet de l'enseignement de l'Anglais</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
        <ul>
            <li>
                09h30 - 10h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                11h30 - 12h45 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                15h30 - 17h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
        </ul>
    </>
)

const Tic = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h15 – 11h30 - F01 - Informations de votre chef de département et de votre responsable de filière</li>
            <li>13h00 – 13h50 - Aula - Informations de l'enseignement de l'Anglais</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
        <ul>
            <li>
                09h30 - 10h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                11h30 - 12h45 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                15h30 - 17h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
        </ul>
    </>
)

const TinGerman = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h15 – 11h30 - Aula - Informations de votre chef de département et de votre responsable de filière</li>
            <li>13h00 – 13h50 - G01 - Informations de l'enseignement de l'Allemand</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
        <ul>
            <li>
                09h30 - 10h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                11h30 - 12h45 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                15h30 - 17h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
        </ul>
    </>
)

const TinEnglish = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h15 – 11h30 - Aula - Informations de votre chef de département et de votre responsable de filière</li>
            <li>13h00 – 13h50 - Aula - Informations de l'enseignement de l'Anglais</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
        <ul>
            <li>
                09h30 - 10h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                11h30 - 12h45 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
            <li>
                15h30 - 17h00 - F04 - Etudiant-e-s qui doivent encore être immatriculé-e-s ou apporter des documents
            </li>
        </ul>
    </>
)

const Comem = () => (
    <>
        <p>Vendredi 10 septembre 2021 à l&#39;AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 09h30 - Aula - Mot de bienvenue de la direction de la HEIG-VD</li>
            <li>10h00 – 11h00 - A01 - Informations de votre chef de département</li>
            <li>11h00 – 13h00 - Pause</li>
            <li>13h00 – 13h50 - Aula - Informations de l&#39;enseignement de l&#39;Anglais</li>
            <li>14h15 – 15h30 - Aula - Présentation de la bibliothèque et du service informatique</li>
        </ul>
    </>
)

const Heg = () => (
    <>
        <p>Jeudi 9 septembre 2021 à l'AULA de la Rte de Cheseaux 1</p>
        <p>Programme :</p>
        <ul>
            <li>08h30 – 10h00 - Site de Cheseaux (Aula) - Accueil des étudiants</li>
            <li>10h00 – 10h30 - Site Cheseaux - Enclassement (formation des classes)</li>
            <li>10h30 – 11h15 - Site Cheseaux - Visite Site de Cheseaux</li>
            <li>11h15 – 12h00 - Site St-Roch - Déplacement direction Site de St-Roch et visite de St-Roch</li>
            <li>12h00 - 13h00 - Cafétéria - Apéritif général sur le Site de St-Roch</li>
        </ul>
        <ul>
            <li>13h00 - 17h00 - S106 - Suite des immatriculation</li>
            <li>13h00 - 17h00 - S107 - Distribution calculettes + Livres professeur-e-s</li>
            <li>Dès 15h00 - Librairie Payot - Ventes livres</li>
        </ul>
    </>
)

const Fallback = () => <p>Cette rebrique est vide pour l'inscription que vous avez choisie.</p>

*/

const Comem = () => (
    <>
        <p>
            <strong>Jeudi 12 septembre 2024 à 9 h 00 (présence obligatoire)</strong>
        </p>
        <p>HEIG-VD, Aula, Route de Cheseaux 1, 1400 Yverdon-les-Bains</p>
        <p>
            Pour vous rendre à notre Journée d'accueil, nous vous encourageons à utiliser les{' '}
            <strong>transports en commun</strong>.
        </p>
        <p>
            L’Association Générale des Étudiant·es (AGE) sera présente tout au long de cette journée et sera ravie de
            vous accueillir et de vous aider à débuter votre vie estudiantine à la HEIG-VD.
        </p>
        <p>
            La participation à notre Journée d'accueil est <strong>obligatoire</strong>. De nombreuses informations
            pratiques vous seront communiquées à cette occasion afin de débuter dans les meilleures conditions.
        </p>
        <p>
            Pour votre repas de midi,{' '}
            <a
                href="https://intra.heig-vd.ch/campus/cafeterias/Pages/menusetprix.aspx"
                target="_blank"
                rel="noopener noreferrer"
            >
                la Cafétéria
            </a>{' '}
            propose des menus dès CHF 9.80, dont un végétarien. Il y a également de la petite restauration, assiette de
            pâtes ou vous pouvez apporter votre repas ; des fours à micro-ondes sont disponibles. De plus, ce jour-là,
            l’AGE met des grils à disposition. Vous pouvez soit, acheter vos salades ainsi que vos grillades à la
            Cafétéria, soit en apporter. L’École offre également un bon de CHF 5.- valable le jour même à la Cafétéria.
        </p>
    </>
)

const Heg = () => (
    <>
        <p>
            <strong>Jeudi 12 septembre 2024 à 8 h 00 (présence obligatoire)</strong>
        </p>
        <p>
            HEIG-VD, <strong>Centre St-Roch</strong>, Av. des sports 20, 1400 Yverdon-les-Bains
        </p>
        <p>
            Nous vous invitons à prendre les <strong>transports en commun</strong> pour vous rendre à cette Journée. Il
            est néanmoins possible de se parquer au parking de la plage (payant). Plus d’information au sujet des{' '}
            <a href="https://heig-vd.ch/campus/mobilite/venir-a-la-heig-vd" target="_blank" rel="noopener noreferrer">
                accès
            </a>{' '}
            sur notre site.
        </p>
        <p>
            Il sera nécessaire de prendre avec vous votre{' '}
            <Link to="/2024/ordinateur-portable">ordinateur portable</Link>.
        </p>
        <p><strong>Programme</strong></p>
        <table class="journee-accueil-table">
            <tr>
                <th>Classes HP11/HP12</th>
                <th>Classes HP13/HE11/HE12</th>
            </tr>
            <tr>
                <td>08h15 - Présentation de la filière</td>
                <td>08h15 - Atelier bureautique</td>
            </tr>
            <tr>
                <td>08h45 – Visite du bâtiment et Pause cafétéria</td>
                <td>09h15 – Visite du bâtiment et Pause cafétéria</td>
            </tr>
            <tr>
                <td>10h30 – Accueil de la direction</td>
                <td>11h00 – Accueil de la direction</td>
            </tr>
            <tr>
                <td>11h00 – Icebreaker</td>
                <td>11h30 – Présentation de la filière</td>
            </tr>
            <tr>
                <td colspan="2">12h00 – Lunch à St-Roch</td>
            </tr>
            <tr>
                <td colspan="2">13h00 - Animation sur le site de Cheseaux</td>
            </tr>
        </table>
        <p>Vous serez informé·es de la classe qui vous sera attribuée quelques jours avant la journée d'accueil.</p>

        <p>
            Le <strong>repas de midi est offert par l’École</strong>,{' '}
            <a
                href="https://intra.heig-vd.ch/campus/cafeterias/Pages/menusetprix.aspx"
                target="_blank"
                rel="noopener noreferrer"
            >
                la Cafétéria
            </a>{' '}
            de la Palmeraie propose deux menus, dont un végétarien.
        </p>
        <p>
            Pour découvrir toutes les facettes de notre Haute École et vous immerger dans la vie de campus,
            rejoignez-nous l'après-midi sur le <strong>site de Cheseaux</strong> ! Des animations captivantes et des
            jeux divertissants, organisés par nos associations vous attendent. Ne manquez pas cette opportunité
            exceptionnelle de vivre une expérience étudiant·e enrichissante et de vous immerger au cœur de notre
            communauté vibrante !
        </p>
    </>
)

const Fallback = () => (
    <>
        <p>
            <strong>Jeudi 12 septembre 2024 à 9 h 00 (présence obligatoire)</strong>
        </p>

        <p>HEIG-VD, Aula, Route de Cheseaux 1, 1400 Yverdon-les-Bains</p>
        <p>
            Pour vous rendre à notre Journée d'accueil, nous vous encourageons à utiliser les{' '}
            <strong>transports en commun</strong>.
        </p>
        <p>
            L’Association Générale des Étudiant·es (AGE) sera présente tout au long de cette journée et sera ravie de
            vous accueillir et de vous aider à débuter votre vie estudiantine à la HEIG-VD.
        </p>
        <p>
            Il sera nécessaire de prendre avec vous votre{' '}
            <Link to="/2024/ordinateur-portable">ordinateur portable</Link>.
        </p>
        <p>
            La participation à notre Journée d'accueil est <strong>obligatoire</strong>. De nombreuses informations
            pratiques vous seront communiquées à cette occasion afin de débuter dans les meilleures conditions.
        </p>
        <p>
            Pour votre repas de midi,{' '}
            <a
                href="https://intra.heig-vd.ch/campus/cafeterias/Pages/menusetprix.aspx"
                target="_blank"
                rel="noopener noreferrer"
            >
                la Cafétéria
            </a>{' '}
            propose des menus dès CHF 9.80, dont un végétarien. Il y a également de la petite restauration, assiette de
            pâtes ou vous pouvez apporter votre repas; des fours à micro-ondes sont disponibles. De plus, ce jour-là,
            l’AGE met des grils à disposition. Vous pouvez soit, acheter vos salades ainsi que vos grillades à la
            Cafétéria, soit en apporter. L’École offre également un bon de CHF 5.- valable le jour même à la Cafétéria.
        </p>
    </>
)

export default ({ path }) => {
    const { student } = Student.useContainer()

    return (
        <div className={baseStyles.root}>
            <h2>Journée d’accueil - Présence obligatoire</h2>
            <PageStatus path={path} label="J'ai réservé la date dans mon agenda" />
            {applyTo(
                student,
                cond([
                    [allPass([isComem]), always(<Comem />)],
                    [allPass([isHeg]), always(<Heg />)],
                    [T, always(<Fallback />)],
                ])
            )}
        </div>
    )

    // return (
    //     <div className={baseStyles.root}>

    //         {isHeg(student) ? (

    //         ) : (
    //             <>
    //                 <p>
    //                     <strong>Jeudi 12 septembre 2024 à 8 h 00 (présence obligatoire)</strong>
    //                     <br />
    //                     HEIG-VD, Aula, Route de Cheseaux 1, 1400 Yverdon-les-Bains
    //                 </p>
    //                 <p>
    //                     Pour vous rendre à notre matinée d'accueil, nous vous encourageons à utiliser les{' '}
    //                     <strong>transports en commun</strong>.
    //                 </p>
    //                 <p>
    //                     Il sera nécessaire de prendre avec vous votre <strong>ordinateur portable</strong> pour le
    //                     configurer avec l'aide de nos collaborateurs du Helpdesk.
    //                 </p>
    //                 <p>
    //                     Votre participation à notre matinée d'accueil est obligatoire. Elle vous est utile pour préparer
    //                     votre rentrée académique. De nombreuses informations pratiques vous seront communiquées. Elles
    //                     aideront à mieux vous guider tout au long de vos études.
    //                 </p>
    //                 <p>
    //                     À partir de midi, l'Association Générale des étudiant·es de la HEIG-VD (AGE) et le Comité du
    //                     Baleinev seront ravis de vous accueillir pour un moment de détente et de convivialité. Ils
    //                     organiseront un barbecue où vous aurez la possibilité d'acheter des grillades à la Cafétéria et
    //                     suivra une après-midi festive.
    //                 </p>
    //             </>
    //         )}
}
