import React from 'react'

export default () => (
    <div>
        <p>
            Chaque collaboratrice et collaborateur de la HEIG-VD s’engage par ses compétences et la qualité de ses
            contributions à garantir les meilleures conditions pour le déroulement des études dans un climat agréable.
            Il est par conséquent attendu de chaque étudiant·e de faire preuve de{' '}
            <strong>respect à l’égard des différentes personnes côtoyées</strong> et de le manifester notamment au
            travers des attitudes suivantes :
        </p>
        <ul>
            <li>L’honnêteté intellectuelle.</li>
            <li>La ponctualité.</li>
            <li>L’amabilité.</li>
            <li>
                Le silence du téléphone portable et la non-utilisation des e-mails, SMS et réseaux sociaux durant les
                cours.
            </li>
            <li>La présence assidue et régulière aux différentes activités d’enseignement.</li>
        </ul>
    </div>
)
