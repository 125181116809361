import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Assurance maladie et accident</h2>
            <PageStatus path={path} label="Je confirme être assuré en cas d'accidents" />
            <p>
                La HEIG-VD ne bénéficie d'aucune assurance maladie et accidents pour les étudiant·es. Vous avez, dès
                lors, <strong>l'obligation de vous assurer personnellement contre la maladie et les accidents.</strong>
            </p>
            <h3>Attention</h3>
            <p>
                Les personnes qui étaient couvertes au préalable par une assurance accidents auprès d'une école ou d'un
                employeur, doivent obligatoirement ajouter à leur assurance maladie de base, la couverture accidents.
            </p>
            <p>
                <strong>
                    Vérifiez auprès de votre caisse d'assurance maladie, que vous êtes couvert·e en cas d'accident.
                </strong>
            </p>
            <p>
                Pour les étudiant·es en provenance de l'étranger, le Secrétariat académique sera à même de vous
                renseigner et vous proposer une assurance maladie et accidents lorsque vous serez immatriculé·e.
            </p>
        </div>
    )
}
