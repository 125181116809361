import { Checkbox, FormControlLabel } from '@material-ui/core'
import * as R from 'ramda'
import React from 'react'
import Auth from './Auth'
import Form from './Form'
import styles from './PageStatus.module.css'
import ViewerMode from './ViewerMode'
const { REACT_APP_API_URI } = process.env

const onChange =
    ({ setAttributeWithPath, token, attribute }) =>
    async (e) => {
        const updatedForm = setAttributeWithPath(attribute, e.target.checked)

        fetch(`${REACT_APP_API_URI}/forms`, {
            method: 'POST',
            body: JSON.stringify(updatedForm),
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
    }

export default ({
    path,
    children,
    attribute = 'read',
    label = "J'ai pris connaissance du contenu de la page",
    showDefaultCheckbox = true,
}) => {
    const { form, setAttribute } = Form.useContainer()
    const { viewerMode } = ViewerMode.useContainer()
    const { token } = Auth.useContainer()
    const value = R.path([path, attribute], form)
    return (
        <div className={styles.root}>
            {showDefaultCheckbox && (
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={viewerMode}
                            checked={value}
                            onChange={onChange({
                                setAttributeWithPath: setAttribute(path),
                                attribute,
                                token,
                            })}
                        />
                    }
                    label={label}
                    className={styles.label}
                />
            )}
            {children}
        </div>
    )
}
