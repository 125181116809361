import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import Student from './Student'
import { anyPass, isNil, isEmpty, not, applyTo, pipe, when } from 'ramda'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: 'linear-gradient( 96.5deg,  rgba(39,103,187,1) 10.4%, rgba(16,72,144,1) 87.7% )',
        paddingTop: 16,
        paddingBottom: 16,
        marginBottom: 0,
    },
    title: {
        margin: 0,
        fontSize: 36,
        fontWeight: 'normal',
        color: 'white',
    },
    choiceWrapper: {
        color: 'white',
        marginTop: 16,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#C4C4C4',
        display: 'block',
        marginBottom: 8,
    },
    choice: {
        fontSize: 16,
        display: 'block',
    },
    date: {
        fontSize: 12,
        display: 'block',
    },
    progress: {
        position: 'absolute',
        right: 16,
        bottom: -70,
    },
    container: {
        position: 'relative',
    },
    logo: {
        width: 48,
        top: 48,
        left: 0,
        position: 'absolute',
    },
}))

const isNilOrEmpty = anyPass([isNil, isEmpty])

const name = ({ lastName, firstName }) => `${firstName} ${lastName}`

const inscription = ({ filiere, orientation, mode }) =>
    applyTo(
        filiere,
        pipe(
            when(
                () => not(isNilOrEmpty(orientation)),
                (x) => `${x}, ${orientation}`
            ),
            (x) => `${x} (${mode})`
        )
    )

export default () => {
    const styles = useStyles()
    const { student } = Student.useContainer()
    return (
        <>
            <div className={styles.root}>
                <Container maxWidth="lg" className={styles.container}>
                    <h1 className={styles.title}>{name(student)}</h1>
                    <div className={styles.choiceWrapper}>
                        <span className={styles.label}>Inscription</span>
                        <span className={styles.choice}>{inscription(student)}</span>
                    </div>
                </Container>
            </div>
        </>
    )
}
