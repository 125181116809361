import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Student from './Student'
import Form from './Form'
import baseStyles from './pages/Base.module.css'

const useStyles = makeStyles({
    list: {
        '& li': {
            margin: '24px 0',
        },
    },
})

const Etudiant = ({ setForm, setStudent, student, description, form = {} }) => {
    const load = () => {
        setStudent(student)
        setForm(form)
    }
    return (
        <li>
            <Button variant="contained" color="primary" onClick={load}>
                {description}
            </Button>
        </li>
    )
}

export default () => {
    const styles = useStyles()
    const { setStudent, student } = Student.useContainer()
    const { setForm } = Form.useContainer()
    const EtudiantWithLoad = (props) => <Etudiant setStudent={setStudent} setForm={setForm} {...props} />
    const validAccount = { passwordHasBeenModified: true, eduIDIsLinked: true, mfaConfigured: true }
    return (
        <div className={baseStyles.root}>
            <h2>Switch student context</h2>
            <ul className={styles.list}>
                <EtudiantWithLoad
                    description="Ingénieur (=> !HEG) + Allemand"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'Allemand',
                        firstName: 'Ingénieur',
                        departement: 'COMEM+',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        emailPrive: 'tmp@heig-vd.ch',
                        languages: 'Allemand',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="Génie territorial"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'territorial',
                        firstName: 'Génie',
                        departement: 'EC+G',
                        filiere: 'Génie territorial',
                        mode: 'Plein temps',
                        emailPrive: 'genie-territorial@heig-vd.ch',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="TIC / Comem : Anglais"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'COMEM+',
                        firstName: 'Etudiant',
                        departement: 'COMEM+',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        emailPrive: 'anglais-not-heg@heig-vd.ch',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="TIN / EC+G : Anglais"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIN',
                        firstName: 'Etudiant',
                        departement: 'TIN',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        emailPrive: 'tmp@heig-vd.ch',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="TIN / EC+G : Allemand"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIN',
                        firstName: 'Etudiant',
                        departement: 'TIN',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        languages: 'Allemand',
                        emailPrive: 'tmp@heig-vd.ch',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="HEG : Anglais + Allemand"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'HEG',
                        firstName: 'Etudiant',
                        departement: 'HEG',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        emailPrive: 'tmp@heig-vd.ch',
                        ...validAccount,
                    }}
                    form={{
                        '/verification-niveaux-langues': {
                            read: false,
                            requestEquivalence: true,
                        },
                    }}
                />
                <EtudiantWithLoad
                    description="COMEM+"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'COMEM+',
                        firstName: 'Etudiant',
                        departement: 'COMEM+',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        emailPrive: 'tmp@heig-vd.ch',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="TIC - PT"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIC',
                        firstName: 'Etudiant',
                        departement: 'TIC',
                        filiere: 'n/a',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="TIC - EE / TP"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIC',
                        firstName: 'Etudiant',
                        departement: 'TIC',
                        filiere: 'n/a',
                        mode: 'En emploi',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="TIN - EE / TP"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIN',
                        firstName: 'Etudiant',
                        departement: 'TIN',
                        filiere: 'n/a',
                        mode: 'En emploi',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="TIN ETE"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIN',
                        firstName: 'Etudiant',
                        departement: 'TIN',
                        filiere: 'Energie et techniques environnementales',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="TIN IGI"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'TIN',
                        firstName: 'Etudiant',
                        departement: 'TIN',
                        filiere: 'Ingénierie et gestion industrielles',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Unités préparatoires: ETE"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'ETE',
                        firstName: 'ETE',
                        departement: 'TIN',
                        filiere: 'Energie et techniques environnementales',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Unités préparatoires: MTEC/SYND/IGIS"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'MTEC',
                        firstName: 'MTEC',
                        departement: 'TIN',
                        filiere: 'Microtechniques',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Unités préparatoires: ELCI"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'ELCI',
                        firstName: 'ELCI',
                        departement: 'TIN',
                        filiere: 'Génie électrique',
                        mode: 'Plein temps',
                        languages: 'Anglais',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Financement des études (!ahesRequired)"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'Doe',
                        firstName: 'John',
                        ahesRequired: false,
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Financement des études (ahesRequired, !ahesFin, visaRequired, !visaAcquired)"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'Doe',
                        firstName: 'John',
                        ahesRequired: true,
                        ahesFin: false,
                        visaRequired: true,
                        visaAcquired: false,
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Financement des études (ahesRequired, ahesFin)"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        lastName: 'Doe',
                        firstName: 'John',
                        ahesRequired: true,
                        ahesFin: true,
                        guid: '9951121c-f4f1-48b3-b466-858ed8c64c2f',
                        ...validAccount,
                    }}
                />
                <EtudiantWithLoad
                    description="Étudiant compte not OK"
                    student={{
                        ...(student.canDebug && { canDebug: true }),
                        firstName: 'Étudiant',
                        lastName: 'comptes pas en ordre',
                        ahesRequired: true,
                        ahesFin: true,
                        guid: '9951121c-f4f1-48b3-b466-858ed8c64c2f',
                        passwordHasBeenModified: true,
                        eduIDIsLinked: true,
                        mfaConfigured: false,
                    }}
                />
            </ul>
        </div>
    )
}
