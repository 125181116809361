import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import { isHeg } from '../lib/utils'
import Student from '../Student'

export default ({ path }) => {
    const { student } = Student.useContainer()
    const href = isHeg(student)
        ? 'https://heig-vd.ch/formation/bachelor/admission/economie-dentreprise/#amenagement-pour-personnes-en-situation-de-handicap-ou-de-maladie'
        : 'https://heig-vd.ch/formation/bachelor/admission/ingenierie/#amenagement-pour-personnes-en-situation-de-handicap-ou-de-maladie'
    return (
        <div className={baseStyles.root}>
            <h2>Situation de handicap ou maladie</h2>
            <PageStatus path={path} />
            <p>
                La HEIG-VD peut mettre en place des aménagements des études pour les étudiant·es en situation de
                handicap ou maladie. Vous trouverez les informations y relatives sur la page{' '}
                <a href={href} target="_blank" rel="noopener noreferrer">
                    Admission - inscription
                </a>
                . <strong>Attention au délai</strong> !
            </p>
        </div>
    )
}
