import { FormControlLabel } from '@material-ui/core'
import React, { useEffect } from 'react'
import { collect } from '../lib/utils'
import PageStatus from '../PageStatus'
import pageStatusStyles from '../PageStatus.module.css'
import State from '../State'
import Student from '../Student'
import baseStyles from './Base.module.css'
import styles from './ComptesAcces.module.css'
import CompteWarning from '../CompteWarning'

const { REACT_APP_API_URI } = process.env

export default ({ path }) => {
    const { student, setStudent } = Student.useContainer()
    const { hasEduID = false } = student
    const [{ passwordHasBeenModified, eduIDIsLinked, mfaConfigured }, setState] = React.useState({
        passwordHasBeenModified: false,
        eduIDIsLinked: false,
        mfaConfigured: false,
    })

    useEffect(() => {
        async function load() {
            const res = await fetch(`${REACT_APP_API_URI}/accounts-statuses`, {
                method: 'POST',
                body: JSON.stringify({ guid: student.guid }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (res.status === 200) {
                const data = await res.json()
                setState(data)
                setStudent({ ...student, ...data })
            }
            if (student.canDebug) {
                setStudent({
                    ...student,
                    passwordHasBeenModified: student.passwordHasBeenModified,
                    eduIDIsLinked: student.eduIDIsLinked,
                    mfaConfigured: student.mfaConfigured,
                })
                setState({
                    passwordHasBeenModified: student.passwordHasBeenModified,
                    eduIDIsLinked: student.eduIDIsLinked,
                    mfaConfigured: student.mfaConfigured,
                })
            }
        }
        load()
    }, [student.guid, student.passwordHasBeenModified, student.eduIDIsLinked, student.mfaConfigured, setStudent])
    return (
        <div className={baseStyles.root}>
            <h2>Comptes d'accès</h2>
            {(!student.passwordHasBeenModified || !student.eduIDIsLinked || !student.mfaConfigured) && (
                <CompteWarning></CompteWarning>
            )}

            <PageStatus path={path}>
                <p className={pageStatusStyles.title}>Statut des actions à entreprendre</p>
                <div className={styles['control-labels']}>
                    <FormControlLabel
                        control={<State done={passwordHasBeenModified} />}
                        label={
                            passwordHasBeenModified
                                ? 'Mot de passe d’accès de la HEIG-VD modifié'
                                : 'Mot de passe d’accès de la HEIG-VD pas encore modifié'
                        }
                        className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                    />
                    <FormControlLabel
                        control={<State done={eduIDIsLinked} />}
                        label={
                            eduIDIsLinked
                                ? 'Compte edu-ID privé correctement lié à la HESSO'
                                : 'Compte edu-ID privé pas encore lié à la HESSO'
                        }
                        className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                    />
                    <FormControlLabel
                        control={<State done={mfaConfigured} />}
                        label={
                            mfaConfigured
                                ? 'Two-factor authentication (2FA) configuré'
                                : 'Two-factor authentication (2FA) pas encore configuré'
                        }
                        className={collect(pageStatusStyles.label, pageStatusStyles.noPointer)}
                    />
                </div>
                {/*
                <div className={pageStatusStyles.info}>
                    <p>
                        Veuillez noter que la vérification automatique du changement de mot de passe a lieu toutes les
                        12 heures.
                    </p>
                </div>
                */}
            </PageStatus>
            <h3>Accès au réseau de la HEIG-VD</h3>
            <p>
                Le login et le mot de passe que vous avez utilisé pour accéder à cette plateforme d’information permet
                également de profiter d’autres ressources de la HEIG-VD :
            </p>
            <ul>
                <li>Réseau</li>
                <li>Wifi</li>
                <li>Intranet de la HEIG-VD</li>
                <li>
                    <a href="https://webmail.heig-vd.ch" target="_blank" rel="noopener noreferrer">
                        Webmail
                    </a>
                </li>
            </ul>
            <p>
                Afin de garantir pleinement la sécurité de votre compte d’accès, vous devez modifier le mot de passe
                initial qui vous a été attribué. Pour ce faire, nous vous invitons à accéder au site suivant
                immédiatement :
            </p>
            <p>
                <a href="https://heig.ch/AAI-login" target="_blank" rel="noopener noreferrer">
                    Modifier le mot de passe de mon login HEIG-VD
                </a>
            </p>

            <p>
                Si vous rencontrer un problème, veuillez prendre contact avec{' '}
                <a href="https://heig-vd.ch/campus/service-informatique" target="_blank" rel="noopener noreferrer">
                    notre helpdesk
                </a>
                .
            </p>
            <h3>Accès au réseau universitaire Suisse</h3>
            <p>
                La HEIG-VD fait partie de la Haute école spécialisée de Suisse occidentale (HES-SO). Ceci vous permet
                ainsi de bénéficier de tous les avantages du réseau SWITCH edu-ID et ainsi de disposer de prestations
                supplémentaires :
            </p>
            <ul>
                <li>suivre des cours en ligne sur Cyberlearn (Moodle)</li>
                <li>se connecter gratuitement depuis n’importe quelle haute-école (EDUROAM)</li>
                <li>bénéficier d’accès gratuit à certains software du monde éducatif</li>
                <li>accéder aux ressources internes de la HESSO</li>
            </ul>
            <p>
                <a href="https://www.hes-so.ch/edu-id" target="_blank" rel="noopener noreferrer">
                    Informations complémentaires
                </a>{' '}
                sur Switch edu-ID
            </p>
            {!hasEduID && (
                <>
                    <p>
                        D’autre part, vous aurez besoin de cet accès pour remplir des informations administratives avant
                        la rentrée, il est donc absolument nécessaire d’avoir :
                    </p>
                    <ol>
                        <li>Créé un compte SWITCH edu-ID (avec un login email privé et un mot de passe privé)</li>
                        <li>
                            Lié le compte SWITCH edu-ID à la HESSO
                            <br />
                            <a
                                href="https://aai-service.hes-so.ch/pwm/private/eduid/linking-start.jsp"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Créer un compte Edu-ID et le lier à mon login et mot de passe de la HEIG-VD
                            </a>
                        </li>
                        <li>
                            Activé l’authentification en 2 étapes
                            <br />
                            <a href="/2024/docs/procedure-activation-2fa.pdf" target="_blank" rel="noopener noreferrer">
                                Procédure d’activation 2FA
                            </a>
                        </li>
                    </ol>
                    <p></p>
                    <h4>Tutoriel vidéo</h4>
                    <div className={styles['video-container']}>
                        <iframe
                            src="https://www.youtube.com/embed/N_ls-Xpmq3g"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </>
            )}
        </div>
    )
}
