import * as React from 'react'
import { Button, Popover, makeStyles } from '@material-ui/core'
import Debug from './Debug'

const useStyles = makeStyles({
    root: {
        backgroundImage: 'linear-gradient( 109.6deg,  rgba(209,0,116,1) 11.2%, rgba(110,44,107,1) 91.1% )',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem',
    },
    close: {
        margin: '2rem',
    },
    wrapper: {
        padding: '1rem',
    },
})

const DebugWrapper = () => {
    const styles = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(undefined)
    const [open, setOpen] = React.useState(false)

    const openClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
    }

    return (
        <div className={styles.root}>
            <Button variant="contained" color="primary" onClick={openClick}>
                Student context
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={styles.popover}
            >
                <div className={styles.wrapper}>
                    <Button variant="contained" className={styles.close} onClick={() => setOpen(false)}>
                        Close
                    </Button>
                    <Debug />
                </div>
            </Popover>
        </div>
    )
}

export default DebugWrapper
