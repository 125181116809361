import { useState, useEffect } from 'react'
import Keycloak from 'keycloak-js'
import { createContainer } from 'unstated-next'
import request from 'request-promise'
import Form from './Form'
import Student from './Student'
import LangAccessCodes from './LangAccessCodes'
import { navigate } from '@reach/router'
import { isEmpty } from 'ramda'
const { REACT_APP_API_URI, REACT_APP_KEYCLOAK_CONFIG } = process.env

export function useAuth() {
    const [auth, setAuth] = useState({ authenticated: undefined })
    const { setForm } = Form.useContainer()
    const { setStudent } = Student.useContainer()
    const { setLangAccessCodes } = LangAccessCodes.useContainer()

    useEffect(() => {
        const keycloak = Keycloak(REACT_APP_KEYCLOAK_CONFIG)
        keycloak
            .init()
            .success((authenticated) => {
                authenticated &&
                    keycloak.loadUserProfile().success((profile) => {
                        request({
                            uri: `${REACT_APP_API_URI}/context`,
                            json: true,
                            headers: {
                                Authorization: `Bearer ${keycloak.token}`,
                            },
                        }).then(async ({ form, student, langAccessCodes }) => {
                            if (isEmpty(student)) {
                                navigate('/unauthorized')
                            } else {
                                if (student.canDebug) {
                                    student.passwordHasBeenModified = true;
                                    student.eduIDIsLinked = true;
                                    student.mfaConfigured = true;
                                }
                                setAuth({
                                    user: profile.username,
                                    token: keycloak.token,
                                    authenticated,
                                })
                                setForm(form)
                                setStudent(student)
                                setLangAccessCodes(langAccessCodes)
                                navigate('/2024/comptes-acces')
                            }
                        })
                    })
            })
            .error(console.error)
    }, [setForm, setStudent, setLangAccessCodes])

    return auth
}

export default createContainer(useAuth)
