import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import { isPt, isEe, isTp } from '../lib/utils'
import Student from '../Student'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Taxe annuelle d'études</h2>
            <PageStatus path={path} />

            {isPt(student) && (
                <>
                    <p>La taxe annuelle d'études est de CHF 1'000.— + CHF 200.— de contributions aux frais d'études.</p>
                    <h3>Arrêt des études dès le 1<sup>er</sup> jour de cours du 1<sup>er</sup> semestre</h3>
                    <p>
                        La taxe d'études d'un semestre, soit CHF 500.— + les contributions aux frais d'études CHF
                        200.- sont dues dès le 1<sup>er</sup> jour de cours.
                    </p>
                </>
            )}

            {(isEe(student) || isTp(student)) && (
                <>
                    <p>La taxe annuelle d'études est de CHF 1'000.— + CHF 150.— de contributions aux frais d'études.</p>
                    <h3>Arrêt des études dès le 1<sup>er</sup> jour de cours du 1<sup>er</sup> semestre</h3>
                    <p>
                        La taxe d'études d'un semestre, soit CHF 500.— + les contributions aux frais d'études (CHF
                        150.—) sont dues dès le 1<sup>er</sup> jour de cours.
                    </p>
                </>
            )}
            <h3>Arrêt des études dès le 1<sup>er</sup> jour de cours du 2<sup>ème</sup> semestre</h3>
            <p>La totalité de la taxe d'études et des contributions aux frais d'études sont dues dès le 1<sup>er</sup> jour de cours.</p>
            <p>&nbsp;</p>
            <p>
                Informations complémentaires relatives aux taxes – voir{' '}
                <a href="https://heig.ch/info-taxes" target="_blank" rel="noopener noreferrer">
                    ici
                </a>
            </p>
        </div>
    )
}
