import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'

export default ({ styles }) => {
    return (
        <ExpansionPanel className={styles.instructions}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Instructions et déroulement</ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <p>
                    Le test de placement en ligne d’Oxford comprend deux sections : Use of English et Listening. Le
                    résultat de chacune des deux parties ainsi que votre résultat d’ensemble nous permettront de vous
                    placer dans un groupe d’anglais dont le niveau correspond à votre niveau. Le questionnaire de profil
                    linguistique ainsi qu’un entretien oral lors de votre première leçon viendront compléter cette
                    évaluation.
                </p>
                <h4>Que prévoir avant de commencer le test ?</h4>
                <ol>
                    <li>Un ordinateur avec accès à Internet</li>
                    <li>Javascript et Adobe FlashTM Player 9</li>
                    <li>Des écouteurs selon besoin</li>
                    <li>Les différents codes annexés à ce document : Student name, User name, Password et Org ID</li>
                    <li>
                        Entre 50 et 80 minutes pour <strong>effectuer le test sans interruption</strong>
                    </li>
                </ol>
                <p>
                    <strong>ATTENTION</strong> : pour obtenir un résultat, le test doit être entièrement effectué dans
                    le temps alloué.
                </p>
                <h4>Commencer le test</h4>
                <ol>
                    <li>Allez sur www.oxfordenglishtesting.com/login</li>
                    <li>
                        Entrez votre code d’utilisateur, votre mot de passe et le code d’identification de
                        l’organisation sous Organisation login.
                    </li>
                    <li>Indiquez votre nom complet ainsi que votre adresse email</li>
                    <li>Activez votre test en cliquant sur Oxford Online Placement Test</li>
                    <li>Choisissez le niveau de difficulté de départ du test en fonction de votre niveau actuel</li>
                    <li>Faites le test en suivant les instructions du site</li>
                </ol>
                <p>
                    <strong>
                        En effectuant ces tests de placement dans le délai fixé, vous contribuez à la bonne organisation
                        des cours de langues et nous vous en remercions par avance.
                    </strong>
                </p>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}
