import React from 'react'
import styles from './CodeOfConductSteps.module.css'

export default ({ children }) => (
    <div>
        <p>
            La <strong>sécurité</strong> de chaque personne au sein de la HEIG-VD étant primordiale, la Haute École a
            mis en place :
        </p>
        <ul>
            <li>
                Des plans d’évacuation, affichés dans chaque bâtiment de la HEIG-VD. Les chemins de fuite sont indiqués
                par une signalétique (panneaux verts). Afin de garantir la sécurité des personnes, il est impératif que
                les chemins de fuite restent dégagés en tout temps.
            </li>
            <li>
                Des extincteurs, placés à des endroits stratégiques dans chaque bâtiment ; ils doivent rester en place
                et bien visibles.
            </li>
        </ul>
        <p>Les étudiant·es son tenu·es se conformer à l’ensemble des directives émises par la Haute École.</p>
        <div className={styles.signature}>
            <p>La Direction</p>
        </div>
        {children}
    </div>
)
