import React from 'react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple'
import green from '@material-ui/core/colors/green'
import CssBaseline from '@material-ui/core/CssBaseline'

const theme = createTheme({
    palette: {
        primary: {
            light: purple[300],
            main: '#20A78F', // purple[500],
            dark: purple[700],
        },
        secondary: {
            light: green[300],
            main: green[500],
            dark: green[700],
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 16,
    },
})

const withRoot = Component => props => (
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
    </MuiThemeProvider>
)

export default withRoot
