import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Votre carte d’étudiant·e</h2>
            <PageStatus path={path} />
            <p>
                <strong>
                    Votre carte est personnelle et intransmissible. En cas de perte ou de changement de photo elle peut
                    être obtenue auprès du Secrétariat académique au prix de CHF 20.—.
                </strong>
            </p>
            <p>Elle vous permet :</p>
            <ul>
                <li>
                    d’emprunter des livres à la Bibliothèque de l'École située à la Route de Cheseaux 1 ou de réserver
                    directement vos ouvrages sur la plateforme{' '}
                    <a href="https://swisscovery.hes-so.ch" target="_blank" rel="noopener noreferrer">
                        swisscovery.hes-so.ch
                    </a>{' '}
                    (inscription préalable sur registration.slsp.ch obligatoire) en utilisant le code-barres de votre
                    carte.
                </li>
                <li>d'imprimer sur les photocopieurs de l'École.</li>
                <li>d'obtenir des rabais dans certaines librairies ou autres.</li>
            </ul>
            <h3>Compte d'impressions</h3>
            <p>Un montant de CHF 30.— annuel sera crédité sur votre compte.</p>
            <h4>Recharger votre crédit d'impressions</h4>
            <p>
                Il vous est possible de recharger votre crédit en passant au Helpdesk. Le montant que vous désirez
                créditer doit être payé au comptant.
            </p>
            <p>
                Pour toute question relative à votre crédit d'impressions, merci de vous adresser directement au Service
                Helpdesk (<a href="mailto:helpdesk@heig-vd.ch">helpdesk@heig-vd.ch</a>).
            </p>
            <h3>Validité de la carte</h3>
            <p>
                La validité du 1<sup>er</sup> semestre s’arrête au <strong>9 mars 2025</strong>. À partir du{' '}
                <strong>mardi 18 février 2025</strong>, vous pourrez prolonger la validité pour le semestre suivant, à
                l’une des bornes dédiées à cet effet. Une borne est située à la Rte Cheseaux au niveau F, dans le box bleu, à côté des distributeurs de boissons. Une seconde borne est située à St-Roch au niveau S, près de l’espace étudiant·e.
            </p>
            <p>
                Merci de contrôler que la validité de votre carte ainsi que votre filière soient correctement indiquées.
                Le Secrétariat académique est à votre disposition en cas d'erreur.
            </p>
        </div>
    )
}
