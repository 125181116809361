import { always, applyTo, cond } from 'ramda'
import React from 'react'
import { isHeg, isComem, isTicTinEcgPt, isTicTpEe, isTinTpEe } from '../lib/utils'
import PageStatus from '../PageStatus'
import Student from '../Student'
import baseStyles from './Base.module.css'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Votre immatriculation</h2>
            <PageStatus
                path={path}
                label="J'ai transmis les originaux de mes diplômes et bulletins de notes d'examens"
            />

            {applyTo(
                student,
                cond([
                    [
                        isTicTinEcgPt,
                        always(
                            <>
                                <h3>Pour les personnes domiciliées en Suisse ou UE</h3>

                                <h4>
                                    A faire obligatoirement avant le{' '}
                                    <span className={baseStyles.nlb}>14 juillet 2024</span>
                                </h4>

                                <p>
                                    <strong>Tous les originaux</strong> de vos diplômes et bulletins de notes d'examens
                                    qui vous donnent accès à la HEIG-VD doivent soit nous parvenir par courrier postal
                                    recommandé, soit être déposés dans la boîte aux lettres dédiée située dans le hall
                                    d'entrée de la Rte de Cheseaux 1.
                                </p>

                                <p>
                                    <strong>
                                        Cette démarche est obligatoire pour que vous soyez admis·e dans notre École.
                                        Demeurent réservées d'autres conditions d'admission relatives à votre dossier.
                                    </strong>
                                </p>

                                <h4>Adresse pour l'envoi de vos documents originaux</h4>
                                <p>
                                    HEIG-VD
                                    <br />
                                    Secrétariat académique
                                    <br />
                                    Ingénierie plein temps
                                    <br />
                                    Rte de Cheseaux 1
                                    <br />
                                    1401 Yverdon-les-Bains
                                </p>

                                <p>Merci de nous communiquer tout changement d'adresse.</p>

                                <p>
                                    Un numéro d'immatriculation OFS des Universités Suisses sera collé au dos de votre
                                    diplôme. Si un numéro vous a déjà été attribué par une autre Haute École ou
                                    Université Suisse, nous noterons le numéro existant.
                                </p>

                                <p>
                                    Tous les documents originaux que vous nous avez transmis vous seront retournés par
                                    courrier postal recommandé dès que votre immatriculation sera terminée.
                                    <br />
                                    Votre carte d'étudiant·e sera mise dans ce même courrier, à condition que votre
                                    dossier d'admission soit complet, que votre questionnaire AHES soit validé par la
                                    HEIG-VD et que vous ayez lu et accepté le Code de savoir-vivre.
                                </p>

                                <h3>Pour les personnes domiciliées à l'étranger (hors UE)</h3>

                                <p>
                                    Votre immatriculation se fera uniquement, lorsque vous serez arrivé·e en Suisse, sur
                                    rendez-vous au Secrétariat académique (+41 24 557 64 08).
                                </p>
                            </>
                        ),
                    ],
                    [
                        isTicTpEe,
                        always(
                            <>
                                <h3>Pour les personnes domiciliées en Suisse ou UE</h3>

                                <h4>
                                    A faire obligatoirement avant le{' '}
                                    <span className={baseStyles.nlb}>14 juillet 2024</span>
                                </h4>

                                <p>
                                    <strong>Tous les originaux</strong> de vos diplômes et bulletins de notes d'examens
                                    qui vous donnent accès à la HEIG-VD doivent soit nous parvenir par courrier postal
                                    recommandé, soit être déposés dans la boîte aux lettres dédiée située dans le hall
                                    d'entrée de la Rte de Cheseaux 1.
                                </p>

                                <p>
                                    <strong>
                                        Cette démarche est obligatoire pour que vous soyez admis·e dans notre École.
                                        Demeurent réservées d'autres conditions d'admission relatives à votre dossier.
                                    </strong>
                                </p>

                                <h4>Adresse pour l'envoi de vos documents originaux</h4>
                                <p>
                                    HEIG-VD
                                    <br />
                                    Secrétariat académique
                                    <br />
                                    Ingénierie en emploi / temps partiel / PiBS
                                    <br />
                                    Rte de Cheseaux 1
                                    <br />
                                    1401 Yverdon-les-Bains
                                </p>

                                <p>Merci de nous communiquer tout changement d'adresse.</p>

                                <p>
                                    Un numéro d'immatriculation OFS des Universités Suisses sera collé au dos de votre
                                    diplôme. Si un numéro vous a déjà été attribué par une autre Haute École ou
                                    Université Suisse, nous noterons le numéro existant.
                                </p>

                                <p>
                                    Tous les documents originaux que vous nous avez transmis vous seront retournés par
                                    courrier postal recommandé dès que votre immatriculation sera terminée.
                                    <br />
                                    Votre carte d'étudiant·e sera mise dans ce même courrier, à condition que votre
                                    dossier d'admission soit complet, que votre questionnaire AHES soit validé par la
                                    HEIG-VD et que vous ayez lu et accepté le Code de savoir-vivre.
                                </p>

                                <h3>Pour les personnes domiciliées à l'étranger (hors UE)</h3>

                                <p>
                                    Votre immatriculation se fera uniquement, lorsque vous serez arrivé·e en Suisse, sur
                                    rendez-vous au Secrétariat académique (+41 24 557 64 92).
                                </p>
                            </>
                        ),
                    ],
                    [
                        isTinTpEe,
                        always(
                            <>
                                <h3>Pour les personnes domiciliées en Suisse ou UE</h3>

                                <h4>
                                    A faire obligatoirement avant le{' '}
                                    <span className={baseStyles.nlb}>14 juillet 2024</span>
                                </h4>

                                <p>
                                    <strong>Tous les originaux</strong> de vos diplômes et bulletins de notes d'examens
                                    qui vous donnent accès à la HEIG-VD doivent soit nous parvenir par courrier postal
                                    recommandé, soit être déposés dans la boîte aux lettres dédiée située dans le hall
                                    d'entrée de la Rte de Cheseaux 1.
                                </p>

                                <p>
                                    <strong>
                                        Cette démarche est obligatoire pour que vous soyez admis·e dans notre École.
                                        Demeurent réservées d'autres conditions d'admission relatives à votre dossier.
                                    </strong>
                                </p>

                                <h4>Adresse pour l'envoi de vos documents originaux</h4>
                                <p>
                                    HEIG-VD
                                    <br />
                                    Secrétariat académique
                                    <br />
                                    Ingénierie en emploi / temps partiel
                                    <br />
                                    Rte de Cheseaux 1
                                    <br />
                                    1401 Yverdon-les-Bains
                                </p>

                                <p>Merci de nous communiquer tout changement d'adresse.</p>

                                <p>
                                    Un numéro d'immatriculation OFS des Universités Suisses sera collé au dos de votre
                                    diplôme. Si un numéro vous a déjà été attribué par une autre Haute École ou
                                    Université Suisse, nous noterons le numéro existant.
                                </p>

                                <p>
                                    Tous les documents originaux que vous nous avez transmis vous seront retournés par
                                    courrier postal recommandé dès que votre immatriculation sera terminée.
                                    <br />
                                    Votre carte d'étudiant·e sera mise dans ce même courrier, à condition que votre
                                    dossier d'admission soit complet, que votre questionnaire AHES soit validé par la
                                    HEIG-VD et que vous ayez lu et accepté le Code de savoir-vivre.
                                </p>

                                <h3>Pour les personnes domiciliées à l'étranger (hors UE)</h3>

                                <p>
                                    Votre immatriculation se fera uniquement, lorsque vous serez arrivé·e en Suisse, sur
                                    rendez-vous au Secrétariat académique (+41 24 557 64 92).
                                </p>
                            </>
                        ),
                    ],
                    [
                        isHeg,
                        always(
                            <>
                                <h3>Pour les personnes domiciliées en Suisse ou UE</h3>

                                <h4>
                                    A faire obligatoirement avant le{' '}
                                    <span className={baseStyles.nlb}>14 juillet 2024</span>
                                </h4>

                                <p>
                                    <strong>Tous les originaux</strong> de vos diplômes et bulletins de notes d'examens
                                    qui vous donnent accès à la HEIG-VD doivent soit nous parvenir par courrier postal
                                    recommandé, soit être déposés dans la boîte aux lettres dédiée située dans le hall
                                    d'entrée de la Rte de Cheseaux 1.
                                </p>

                                <p>
                                    <strong>
                                        Cette démarche est obligatoire pour que vous soyez admis·e dans notre École.
                                        Demeurent réservées d'autres conditions d'admission relatives à votre dossier.
                                    </strong>
                                </p>

                                <h4>Adresse pour l'envoi de vos documents originaux</h4>
                                <p>
                                    HEIG-VD
                                    <br />
                                    Economie d'entreprise
                                    <br />
                                    Rte de Cheseaux 1
                                    <br />
                                    1401 Yverdon-les-Bains
                                </p>

                                <p>Merci de nous communiquer tout changement d'adresse.</p>

                                <p>
                                    Un numéro d'immatriculation OFS des Universités Suisses sera collé au dos de votre
                                    diplôme. Si un numéro vous a déjà été attribué par une autre Haute École ou
                                    Université Suisse, nous noterons le numéro existant.
                                </p>

                                <p>
                                    Tous les documents originaux que vous nous avez transmis vous seront retournés par
                                    courrier postal recommandé dès que votre immatriculation sera terminée.
                                    <br />
                                    Votre carte d'étudiant·e sera mise dans ce même courrier, à condition que votre
                                    dossier d'admission soit complet, que votre questionnaire AHES soit validé par la
                                    HEIG-VD et que vous ayez lu et accepté le Code de savoir-vivre.
                                </p>

                                <h3>Pour les personnes domiciliées à l'étranger (hors UE)</h3>

                                <p>
                                    Votre immatriculation se fera uniquement, lorsque vous serez arrivé·e en Suisse, sur
                                    rendez-vous au Secrétariat académique (+41 24 557 64 92).
                                </p>
                            </>
                        ),
                    ],
                    [
                        isComem,
                        always(
                            <>
                                <h3>Pour les personnes domiciliées en Suisse ou UE</h3>

                                <h4>
                                    A faire obligatoirement avant le{' '}
                                    <span className={baseStyles.nlb}>14 juillet 2024</span>
                                </h4>

                                <p>
                                    <strong>Tous les originaux</strong> de vos diplômes et bulletins de notes d'examens
                                    qui vous donnent accès à la HEIG-VD doivent soit nous parvenir par courrier postal
                                    recommandé, soit être déposés dans la boîte aux lettres dédiée située dans le hall
                                    d'entrée de l'Avenue des Sports 20.
                                </p>

                                <p>
                                    <strong>
                                        Cette démarche est obligatoire pour que vous soyez admis·e dans notre École.
                                        Demeurent réservées d'autres conditions d'admission relatives à votre dossier.
                                    </strong>
                                </p>

                                <h4>Adresse pour l'envoi de vos documents originaux</h4>
                                <p>
                                    HEIG-VD
                                    <br />
                                    Ingénierie des médias
                                    <br />
                                    Av. des Sports 20
                                    <br />
                                    1401 Yverdon-les-Bains
                                </p>

                                <p>Merci de nous communiquer tout changement d'adresse.</p>

                                <p>
                                    Un numéro d'immatriculation OFS des Universités Suisses sera collé au dos de votre
                                    diplôme. Si un numéro vous a déjà été attribué par une autre Haute École ou
                                    Université Suisse, nous noterons le numéro existant.
                                </p>

                                <p>
                                    Tous les documents originaux que vous nous avez transmis vous seront retournés par
                                    courrier postal recommandé dès que votre immatriculation sera terminée.
                                    <br />
                                    Votre carte d'étudiant·e sera mise dans ce même courrier, à condition que votre
                                    dossier d'admission soit complet, que votre questionnaire AHES soit validé par la
                                    HEIG-VD et que vous ayez lu et accepté le Code de savoir-vivre.
                                </p>

                                <h3>Pour les personnes domiciliées à l'étranger (hors UE)</h3>

                                <p>
                                    Votre immatriculation se fera uniquement, lorsque vous serez arrivé·e en Suisse, sur
                                    rendez-vous au Secrétariat académique (+41 24 557 76 04).
                                </p>
                            </>
                        ),
                    ],
                ])
            )}
        </div>
    )
}
