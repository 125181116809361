import React from 'react'
import styles from './PageStatus.module.css'

export default () => {
    return (
        <div className={styles.account}>
            IMPORTANT : tant que les actions à entreprendre concernant vos comptes d'accès n'ont pas été complétées
            (voir ci-dessous), vous ne pouvez pas avoir accès aux autres rubriques et ainsi être immatriculé·e{' '}
        </div>
    )
}
