import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Cours de marketing en français ou en anglais</h2>
            <PageStatus path={path} />
            <p>
                Certains cours de la formation sont proposés à choix en français ou en anglais. Ces opportunités
                permettent non seulement d’approfondir ses compétences linguistiques, mais également d’aborder une
                matière dans sa langue d’usage en entreprise. Ainsi, pour les étudiant·es en 1ère année à plein temps,
                les cours Marketing 1 et Marketing 2 sont proposés dans les deux langues (ces cours sont donnés en 2ème
                année en emploi/temps partiel).
            </p>
            <p>
                Les étudiants inscrits à plein temps sont donc priés de nous indiquer leur choix au moyen de{' '}
                <a
                    href="https://enquete.heig-vd.ch/SurveyServer/s/HEG_Gestion_Cours/MKT1ere/questionnaire.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    CE FORMULAIRE
                </a>
                .
            </p>
        </div>
    )
}
