import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'
import Student from '../Student'
import { applyTo, cond, always, T } from 'ramda'
import { isHeg, isComem, isTicTinEcgPt, isTicTpEe, isTinTpEe } from '../lib/utils'

export default ({ path }) => {
    const { student } = Student.useContainer()
    return (
        <div className={baseStyles.root}>
            <h2>Renonciation</h2>
            <PageStatus path={path} />
            <p>
                Dans le cas où vous renonceriez à débuter vos études à la HEIG-VD,{' '}
                <strong>il est impératif que vous nous le communiquiez</strong> rapidement par{' '}
                {applyTo(
                    student,
                    cond([
                        [
                            isTicTinEcgPt,
                            always(
                                <>
                                    <a href="mailto:raffaela.beguelin@heig-vd.ch">courrier électronique</a>
                                </>
                            ),
                        ],
                        [
                            isTicTpEe || isTinTpEe,
                            always(
                                <>
                                    <a href="mailto:celine.graf@heig-vd.ch">courrier électronique</a>
                                </>
                            ),
                        ],
                        [
                            isComem,
                            always(
                                <>
                                    <a href="mailto:daniela.oberlojer@heig-vd.ch">courrier électronique</a>
                                </>
                            ),
                        ],
                        [
                            isHeg,
                            always(
                                <>
                                    <a href="mailto:veronique.furst@heig-vd.ch">courrier électronique</a>
                                </>
                            ),
                        ],
                        [T, always(<a href="mailto:raffaela.beguelin@heig-vd.ch">courrier électronique</a>)],
                    ])
                )}
                .
            </p>
        </div>
    )
}
