import React from 'react'

export default () => (
    <div>
        <p>
            Respectueux de l’<strong>environnement</strong> et des <strong>questions énergétiques</strong>, et dans
            l’esprit d’un campus vert, les membres du personnel adoptent des comportements qui s’appliquent également
            aux étudiant·es :
        </p>
        <ul>
            <li>Eteindre la lumière en sortant d’une pièce.</li>
            <li>Déconnecter les ordinateurs et appareils électriques après utilisation.</li>
            <li>Fermer les fenêtres la nuit et les jours sans cours.</li>
            <li>
                Adopter les bons gestes en matière de recyclage et utiliser les collecteurs disposés dans les différents
                bâtiments.
            </li>
            <li>Privilégier l’utilisation des escaliers à celle des ascenseurs.</li>
        </ul>
    </div>
)
