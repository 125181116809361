import React from 'react'
import baseStyles from './Base.module.css'
import PageStatus from '../PageStatus'

export default ({ path }) => {
    return (
        <div className={baseStyles.root}>
            <h2>Attestation semestrielle d'études</h2>
            <PageStatus path={path} />
            <p>Votre attestation semestrielle d'études sera disponible dès le lundi 16 septembre 2024 depuis <a
                        href="https://intra.heig-vd.ch/academique/attestation-etude/Pages/default.aspx"
                        target="_blank"
                        rel="noopener noreferrer">cette page Intranet</a>, pour autant que votre dossier d'admission soit finalisé (envoi des diplômes originaux et documents manquants) et que votre formulaire AHES soit validé par la HEIG-VD.</p>
            <p>Par la suite, vous aurez la possibilité d'en générer une en tout temps depuis l'Intranet.</p>
            <p><strong>Aucune attestation d'études ne sera disponible avant le 16 septembre 2024 (1<sup>er</sup> jour ouvré de l'année académique 2024-2025) quel que soit l'objet de votre demande.</strong></p>
        </div>
    )
}
