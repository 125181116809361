import React from 'react'
import { Link, Location } from '@reach/router'
import styles from './TOCEntry.module.css'
import { pipe, trim, join, all, equals, values, filter } from 'ramda'
import DoneIcon from '@material-ui/icons/Done'
import Form from './Form'
import Student from './Student'

const collect = pipe(join(' '), trim)

const customCompleteValidators = {
    '/verification-niveaux-langues': (x) =>
        x &&
        x.read &&
        (typeof x.requestEquivalence !== 'undefined' ||
            (typeof x.requestEquivalenceForEnglish !== 'undefined' &&
                typeof x.requestEquivalenceForGermanOrItalian !== 'undefined')),
    '/demande-equivalence': (x) => x && x.read,
    '/financement-de-vos-etudes': (x, { ahesFin = false }) => x.readAndFilledAhesForm && ahesFin,
}

const isCompleted = (o) =>
    all(
        equals(true),
        filter((x) => typeof x === 'boolean', values(o))
    )

export default (props) => {
    const { form } = Form.useContainer()
    const { student } = Student.useContainer()

    const { to, disabled } = props

    const done =
        form[to] !== undefined &&
        (customCompleteValidators[to] ? customCompleteValidators[to](form[to], student) : isCompleted(form[to]))
    return (
        <Location>
            {({ location }) => {
                const { pathname } = location
                return (
                    <li
                        className={collect([
                            styles.root,
                            pathname === to ? styles.active : null,
                            done ? styles.done : null,
                        ])}
                        onClick={(e) => {
                            if (disabled) {
                                e.preventDefault()
                                alert(
                                    "Vous devez impérativement prendre connaissance du contenu de la page « Comptes d'accès » et compléter les actions à entreprendre afin de pouvoir continuer."
                                )
                            }
                        }}
                    >
                        <div className={styles.iconWrapper}>
                            {done && <DoneIcon fontSize="inherit" color="inherit" />}
                        </div>

                        <Link
                            {...props}
                            className={
                                disabled
                                    ? collect([styles.link, styles.disabled])
                                    : collect([styles.link, done ? styles.done : null])
                            }
                        />
                    </li>
                )
            }}
        </Location>
    )
}
